"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reconciliationBuySchema = void 0;
/* eslint-disable @typescript-eslint/naming-convention */
exports.reconciliationBuySchema = {
    $id: 'https://buyplan.nike.com/reconciliationBuySchema.json',
    $schema: 'http://json-schema.org/schema#',
    type: 'object',
    additionalProperties: true,
    properties: {
        MATERIAL: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        'STORE NUMBER': {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        PARTNER: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        WOS: {
            type: 'integer',
            minimum: 0,
            maximum: 53,
        },
        ROS: {
            type: 'number',
        },
        'ST%': {
            type: 'number',
            exclusiveMinimum: 0,
            maximum: 1, // Ensures value must be less than or equal to 1
        },
        OPERATION: {
            type: 'string',
            enum: ['A', 'U', 'R', 'N'],
        },
    },
    errorMessage: {
        required: {
            MATERIAL: 'Material is required',
            'STORE NUMBER': 'Store number is required',
            PARTNER: 'Partner is required',
            WOS: 'Weeks on sale is required',
            ROS: 'Rate of sale is required',
            'ST%': 'Sell through is required',
            OPERATION: 'Operation is required',
        },
        properties: {
            MATERIAL: 'Material code must be a string with a minimum length of 1 and a maximum length of 45',
            'STORE NUMBER': 'Store number must be a string with a minimum length of 1 and a maximum length of 45',
            PARTNER: 'Partner must be a string with a minimum length of 1 and a maximum length of 45',
            WOS: 'Weeks on sale must be a number',
            ROS: 'Rate of sale must be a number',
            'ST%': 'Sell Through % must be a number',
            OPERATION: 'Operation must be A for add / U for update / R for remove / N for no operation',
        },
    },
    required: ['MATERIAL', 'STORE NUMBER', 'PARTNER', 'WOS', 'ROS', 'ST%', 'OPERATION'],
};
