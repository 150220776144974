import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loader from '../Loader/Loader';
import './SourceFileOptions.scss';

interface Props {
    onReUploadClick: () => void;
    onDeleteClick?: () => void;
    deleting: boolean;
}

const BUTTON_WIDTH = 100;

function SourceFileOptions({ onDeleteClick, onReUploadClick, deleting }: Props) {
    const [confirmedDelete, setConfirmedDelete] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        const buttonsWidth = onDeleteClick ? 2 * BUTTON_WIDTH : BUTTON_WIDTH;
        setWidth(buttonsWidth);
    }, [onDeleteClick]);

    useEffect(
        () => () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        },
        []
    );

    const handleDelete = async () => {
        if (!onDeleteClick) return;

        if (!confirmedDelete) {
            setConfirmedDelete(true);
            timeoutRef.current = setTimeout(() => {
                setConfirmedDelete(false);
            }, 3000);
            return;
        }

        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setConfirmedDelete(false);
        await onDeleteClick();
    };

    return (
        <div style={{ width }} className="SourceFileOptions">
            <span className="SourceFileOptions__reupload">
                <button className="SourceFileOptions__reuploadbutton" type="button" onClick={onReUploadClick} tabIndex={-1}>
                    <FontAwesomeIcon icon={faRedoAlt as IconProp} />
                    <span>re-upload file</span>
                </button>
            </span>
            {onDeleteClick ? (
                <span className="SourceFileOptions__delete">
                    <button className="SourceFileOptions__deletebutton" type="button" onClick={handleDelete} tabIndex={-1}>
                        {deleting ? (
                            <>
                                <Loader width={32} />
                                <span>deleting...</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faTrash as IconProp} />
                                {confirmedDelete ? <span>really delete?</span> : <span>delete file</span>}
                            </>
                        )}
                    </button>
                </span>
            ) : null}
        </div>
    );
}

export default SourceFileOptions;
