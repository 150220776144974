import React from 'react';
import {
    AssortmentClusterOptionCounts,
    AssortmentStoreOptionCounts,
    Material,
    AssortmentDetails,
    ChannelRole,
    AssortmentRecord,
    MaterialLockedForPartnerAssortment,
    canUpdateDigitalBuyplan,
    AssortmentClusterAction,
} from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';
import { useSelector } from '../../store/reducers';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import { PARTNER_PORTAL_PARTNERS } from '../../constants/partnersConfig';
import AssortmentStore from './AssortmentStore';
import './AssortmentCluster.scss';

interface Props {
    material: Material;
    clusterOptionCount: AssortmentClusterOptionCounts;
    lockedForPartnersAssortment: MaterialLockedForPartnerAssortment[];
    assortmentRecords: AssortmentDetails[];
    removeAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    addToAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    updateAssortmentCluster({
        channelId,
        action,
        materialCode,
        clusterId,
        clusterAssortmentStores,
    }: {
        channelId: number;
        action: AssortmentClusterAction;
        materialCode: string;
        clusterId: string;
        clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
    }): Promise<void>;
    isOpen: boolean;
    assortAllLoading: boolean;
}

enum CheckboxState {
    All,
    Partial,
    None,
}

function AssortmentCluster({
    material,
    clusterOptionCount,
    assortmentRecords,
    isOpen,
    lockedForPartnersAssortment,
    addToAssortment,
    removeAssortment,
    assortAllLoading,
    updateAssortmentCluster,
}: Props) {
    const { activeChannelId } = useSelector(({ user }) => user.settings);
    const { storeOptionCounts } = clusterOptionCount;
    const materialStoreOptionCounts = storeOptionCounts.filter(({ categoryDivisionPairs }: AssortmentStoreOptionCounts) =>
        categoryDivisionPairs.find(
            ({ category, division }: { category: string; division: string }) =>
                category === material.category && division === material.division
        )
    );

    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);
    const records = assortmentRecords.filter(
        ({ materialCode, clusterId }) => materialCode === material.materialCode && clusterId === clusterOptionCount.clusterId
    );

    // Return your JSX here
    let checkboxValue: CheckboxState;
    if (records.length === 0) {
        checkboxValue = CheckboxState.None;
    } else if (materialStoreOptionCounts.length === records.length) {
        checkboxValue = CheckboxState.All;
    } else {
        checkboxValue = CheckboxState.Partial;
    }

    let checkboxIcon: JSX.Element | null;
    if (checkboxValue === CheckboxState.All) {
        checkboxIcon = (
            <FontAwesomeIcon className="AssortmentDetailCheckbox__allCheckCircle" size="2x" icon={faCheck as IconProp} />
        );
    } else if (checkboxValue === CheckboxState.None) {
        checkboxIcon = null;
    } else {
        checkboxIcon = (
            <FontAwesomeIcon className="AssortmentDetailCheckbox__allCheckMinus" size="2x" icon={faMinus as IconProp} />
        );
    }
    const disabled = isReadOnlyUser || materialStoreOptionCounts.length === 0;
    const filterIPPStores = (storeNumber: string) =>
        canUpdateDigitalBuyplan(activeChannelId, storeNumber, material.typeOfOrder);

    const handleChange = async () => {
        const action =
            checkboxValue !== CheckboxState.All ? AssortmentClusterAction.ASSORT : AssortmentClusterAction.UNASSORT;

        const clusterAssortmentStores = materialStoreOptionCounts
            .filter(({ number }: AssortmentStoreOptionCounts) => {
                if (action === AssortmentClusterAction.ASSORT) {
                    for (let i = 0; i < assortmentRecords.length; i++) {
                        const assortmentRecord = assortmentRecords[i];
                        if (
                            assortmentRecord.storeNumber === number &&
                            assortmentRecord.materialCode === material.materialCode
                        ) {
                            return false;
                        }
                    }
                }
                return filterIPPStores(number);
            })
            .map(({ storeId, number }: AssortmentStoreOptionCounts) => ({
                clusterId: clusterOptionCount.clusterId,
                storeId,
                storeNumber: number,
            }));

        updateAssortmentCluster({
            channelId: activeChannelId,
            materialCode: material.materialCode,
            clusterId: clusterOptionCount.clusterId,
            action,
            clusterAssortmentStores,
        });
    };

    return (
        <>
            <div
                className={cn('AssortmentDetailCheckbox', 'AssortmentDetailCheckbox__allCheck', {
                    'AssortmentDetailCheckbox__allCheck--disabled': disabled,
                })}
            >
                <button type="button" disabled={disabled} onClick={handleChange}>
                    {!disabled ? checkboxIcon : null}
                </button>
            </div>
            {isOpen && (
                <div className="AssortmentCluster__stores">
                    {storeOptionCounts.map((storeOptionCount: AssortmentStoreOptionCounts) => (
                        <AssortmentStore
                            clusterId={clusterOptionCount.clusterId}
                            assortAllLoading={assortAllLoading}
                            key={`${material.materialCode}-${storeOptionCount.number}`}
                            storeOptionCount={storeOptionCount}
                            assortmentRecords={assortmentRecords}
                            material={material}
                            enabledForPartnerAssortment={
                                !!PARTNER_PORTAL_PARTNERS.find(
                                    (partnerHasAccessToPartnerPortal) =>
                                        storeOptionCount.partner === partnerHasAccessToPartnerPortal
                                )
                            }
                            isMaterialLockedForPartnerAssortment={
                                !!lockedForPartnersAssortment.find(
                                    (record) => record.partner === storeOptionCount.partner && record.isLocked
                                )
                            }
                            addToAssortment={addToAssortment}
                            removeAssortment={removeAssortment}
                            isDisabled={
                                !storeOptionCount.categoryDivisionPairs.find(
                                    ({ category, division }: { category: string; division: string }) =>
                                        category === material.category && division === material.division
                                )
                            }
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default AssortmentCluster;
