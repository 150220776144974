import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NewRelicEventName, channels, AsyncJobStatusEnum } from 'buyplan-common';
import { OnProgress } from '../../api/api';
import {
    getReconciliationPartners,
    uploadReconciliationFile,
    PartnerReconciliation,
    convertReconciliationFiles,
    getJobStatus,
} from '../../services/reconciliationService';
import { recursionGetJobStatus } from '../../services/asyncJobUploadFileService';
import ImportFiles from '../ImportFiles/ImportFiles';
import Loader from '../Loader/Loader';
import useUserSettings from '../../selectors/useUserSettings';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import './Reconciliation.scss';

const usePartnerStatus = () => {
    const { activeSeasonId } = useUserSettings();
    const [partners, setPartners] = useState<PartnerReconciliation[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>();

    const startPollingForPartner = async (partner: PartnerReconciliation) => {
        if (!partner.job) {
            return;
        }
        const fetchStatus = getJobStatus(partner.job.jobId);
        const res = await recursionGetJobStatus(fetchStatus);

        setPartners((prevPartners) => prevPartners.map((p) => (p.id === partner.id ? { ...p, job: res } : p)));
    };

    useEffect(() => {
        const fetchInitialPartners = async () => {
            setLoading(true);
            try {
                const res = await getReconciliationPartners(activeSeasonId);
                const reconciliationPartners = res?.data ?? [];
                setPartners(reconciliationPartners);

                // Start polling for partners that are "in progress" / "started"
                reconciliationPartners
                    .filter((partner) =>
                        [AsyncJobStatusEnum.inProgress, AsyncJobStatusEnum.started].includes(
                            partner.job?.status as AsyncJobStatusEnum
                        )
                    )
                    .forEach(startPollingForPartner);
            } catch (err: unknown) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialPartners();
    }, [activeSeasonId]);

    return { partners, loading, error };
};

function Reconciliation() {
    const { partners, loading, error } = usePartnerStatus();
    const { activeChannelId, activeSeasonId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const [partnerReconciliationStatus, setPartnerReconciliationStatus] = useState<PartnerReconciliation[]>([]);

    useEffect(() => {
        setPartnerReconciliationStatus(partners ?? []);
    }, [partners]);

    if (!isChannelAdmin || activeChannelId !== channels.nds.id) {
        return <Redirect to="/buyplan" />;
    }

    const handleUpload = async (file: File, _: string, onProgress: OnProgress, partnerId: string) => {
        if (file === null) {
            return;
        }
        const partnerReconciliationResult = await uploadReconciliationFile(partnerId, activeSeasonId, file, onProgress);

        setPartnerReconciliationStatus((prev) =>
            prev.map((partner) => (partner.id === partnerId ? { ...partner, job: partnerReconciliationResult } : partner))
        );
    };

    const handleDismiss = (partnerId: string) => {
        setPartnerReconciliationStatus((prev) =>
            prev.map((partner) => (partner.id === partnerId ? { ...partner, job: undefined } : partner))
        );
    };
    return (
        <Container>
            <h1>RECONCILIATION PER PARTNER {loading && <Loader width={16} />}</h1>
            {error && (
                <div className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}{' '}
            <Row>
                {partnerReconciliationStatus.map((partner: PartnerReconciliation) => (
                    <Col key={partner.id} sm={{ span: 6 }}>
                        <div className="ImportReconciliationFiles">
                            <section>
                                <h3>{partner.name}</h3>
                                <ImportFiles
                                    files={convertReconciliationFiles(partner.job)}
                                    onCancelError={() => handleDismiss(partner.id)}
                                    onUpload={(...args) => handleUpload(...args, partner.id)}
                                    onReUpload={(...args) => handleUpload(...args, partner.id)}
                                    onSuccess={() => {}}
                                    narrow={true}
                                    confirmation={() => ({
                                        title: 'Are you sure?',
                                        subtitle: `This will update buy plan for ${partner.name}`,
                                        message: 'Uploading of the file cannot be undone',
                                    })}
                                    eventTypeTracker={NewRelicEventName.reconciliationFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Reconciliation;
