import {
    AllFilters,
    FilteringMetaData,
    MainFilters,
    AssortmentRecord,
    AssortmentData,
    MaterialLockedForPartnerAssortment,
    AssortmentClusterAction,
} from 'buyplan-common';
import { omit } from 'lodash';
import { OnProgress, request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { upload } from './uploadFileService';

export const getPartners = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/partners`,
    };

    return request<{ data: { partner: string }[] }>(requestOptions, true);
};

export const getMetaData = (
    filterTypes = ['partner', 'category', 'division'],
    { category, division, partner }: MainFilters = {},
    includeSubFilters = true,
    signal?: AbortSignal
) => {
    const queryParams = {
        filter: { category, division, partner },
        includeSubFilters,
        filterTypes,
    };

    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/meta`,
        signal,
        queryParams,
    };

    return request<{ data: FilteringMetaData }>(requestOptions);
};

export const getAssortment = (
    activeFilters: AllFilters,
    page: number,
    pageSize: number,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal: AbortSignal
) => {
    const queryParams = {
        filter: omit(activeFilters, 'storeId'),
        page,
        pageSize,
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment`,
        queryParams,
        signal,
    };

    return request<{ data: AssortmentData; meta: { page: number; pageSize: number } }>(requestOptions);
};

export const uploadMerchandiseFile = async (
    file: File,
    uuid: string,
    onProgress: OnProgress,
    queryParams?: Record<string, unknown>
) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `merchandise-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/merchandisefiles`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            uuid,
        },
        queryParams,
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        suppressGlobalError: true,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        uploadPostProcessWith: requestOptions,
    });
};

export const reuploadMerchandiseFile = async (file: File, uuid: string, onProgress: OnProgress) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `merchandise-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/merchandisefiles/${uuid}`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        suppressGlobalError: true,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        uploadPostProcessWith: requestOptions,
    });
};

export const deleteMerchandiseFile = async (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/merchandisefiles/${uuid}`,
    };

    await request(requestOptions);
};

type UpdateClustersAssortmentRecordsParams = { clusterIds: string[]; action: AssortmentClusterAction; materialCode: string };

export const updateClustersAssortmentRecords = async (
    activeFilters: AllFilters,
    { action, clusterIds, materialCode }: UpdateClustersAssortmentRecordsParams
) => {
    const queryParams = {
        filter: activeFilters,
    };

    const requestOptions = {
        method: 'PATCH',
        data: { clusterIds, materialCode, action },
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/clusters`,
        queryParams,
    };

    await request(requestOptions);
};

export const updateMaterialLockForPartnerAssortment = async (
    materialLockedForPartnersAssortment: MaterialLockedForPartnerAssortment[]
) => {
    const requestOptions = {
        method: 'PATCH',
        data: { materialLockedForPartnersAssortment },
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/lock`,
    };
    await request(requestOptions);
};

export const addAssortmentRecord = async ({ storeId, materialCode }: AssortmentRecord) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/stores/${storeId}/${materialCode}`,
    };

    await request(requestOptions);
};

export const removeAssortmentRecord = async ({ storeId, materialCode }: AssortmentRecord) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/stores/${storeId}/${materialCode}`,
    };

    await request(requestOptions);
};

export const bulkUpdateFilteredMaterials = async (
    activeFilters: AllFilters,
    field: string,
    value: number | string,
    method: string
) => {
    const queryParams = {
        filter: activeFilters,
    };
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/assortment/bulk-update`,
        queryParams,
        data: {
            field,
            value,
            method,
        },
    };

    return request<{ message: string | undefined }>(requestOptions);
};
