import React from 'react';
import { channels } from 'buyplan-common';
import { NavLink } from 'react-router-dom';
import useSeasonSettings from '../../../selectors/useSeasonSettings';
import useIsChannelAdmin from '../../../selectors/useIsChannelAdmin';
import useUserProfile from '../../../selectors/useUserProfile';
import useUserSettings from '../../../selectors/useUserSettings';
import { useIsReconciliationEnabled } from '../../../selectors/useFeatureFlags';

function BuyplanSubnavigation() {
    const profile = useUserProfile();
    const { activeChannelId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const { isEditableSeason } = useSeasonSettings();
    const isReconciliationEnabled = useIsReconciliationEnabled();
    if (!profile) {
        return null;
    }

    return (
        <ul className="buyplan-navigation__buy-plan-dropdown">
            <li>
                <NavLink to="/buyplan/view-buy-plan">
                    <span>View Buy Plan</span>
                </NavLink>
            </li>
            {isChannelAdmin && (
                <>
                    {isEditableSeason && (
                        <li>
                            <NavLink to="/buyplan/import-files">
                                <span>Import Buy Plan files</span>
                            </NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink to="/buyplan/sales-targets">
                            <span>Sales targets</span>
                        </NavLink>
                    </li>
                </>
            )}
            <li>
                <NavLink to="/buyplan/margin-impact">
                    <span>Margin Impact</span>
                </NavLink>
            </li>
            {isReconciliationEnabled && isChannelAdmin && activeChannelId === channels.nds.id && (
                <li>
                    <NavLink to="/buyplan/reconciliation">
                        <span>Reconciliation by partner</span>
                    </NavLink>
                </li>
            )}
        </ul>
    );
}

export default BuyplanSubnavigation;
