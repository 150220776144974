import { AsyncJobStatusEnum, SourceFile, FileValidationErrors } from 'buyplan-common';
import { OnProgress, request } from '../api/api';
import { uploadFileWithAsyncJob, AsyncJobStatus } from './asyncJobUploadFileService';

const reconciliationFileDefaultName = 'Reconciliation';
const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type PartnerReconciliation = {
    id: string;
    name: string;
    job?: AsyncJobStatus;
};

export type GetPartnerReconciliationResponse = {
    objects: PartnerReconciliation[];
};

export type PartnerReconciliationResponse = {
    jobId: string;
    response: string;
    status: AsyncJobStatusEnum;
};

export const getReconciliationPartners = async (seasonId: number) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/partner-reconciliation/v1`,
        queryParams: {
            seasonId,
        },
    };

    const result = await request<GetPartnerReconciliationResponse>(requestOptions);
    return { data: result.objects };
};

export const startReconciliationJob = async (partnerId: string, seasonId: number) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/partner-reconciliation/v1`,
        data: {
            seasonId,
            partnerId,
        },
    };

    return request<PartnerReconciliationResponse>(requestOptions);
};

export const getJobStatus = (jobId: string) => async (): Promise<AsyncJobStatus> => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/partner-reconciliation/v1/status`,
        data: {
            jobId,
        },
    };
    return request<AsyncJobStatus>(requestOptions);
};

export const uploadReconciliationFile = async (
    partnerId: string,
    seasonId: number,
    file: File,
    onProgress: OnProgress
): Promise<PartnerReconciliationResponse | undefined> => {
    const { status, response: signedUrl, jobId } = await startReconciliationJob(partnerId, seasonId);
    if (status !== AsyncJobStatusEnum.started) {
        return undefined;
    }

    const fetchStatus = getJobStatus(jobId);
    const result = await uploadFileWithAsyncJob({
        file,
        url: signedUrl,
        onProgress,
        fetchStatus,
        mimeType: excelMimeType,
    });
    return result;
};

export const convertReconciliationFiles = (reconciliationJobStatus?: AsyncJobStatus): SourceFile[] => {
    if (!reconciliationJobStatus) return [];

    const { status, jobId, createdAt, response } = reconciliationJobStatus;
    if (status === AsyncJobStatusEnum.started)
        return [
            {
                uuid: jobId,
                updatedAt: createdAt,
                filename: `${reconciliationFileDefaultName} sucessfully started`,
                progress: 10,
                mimetype: excelMimeType,
            },
        ];
    if (status === AsyncJobStatusEnum.failed)
        return [
            {
                uuid: jobId,
                updatedAt: createdAt,
                mimetype: excelMimeType,
                filename: `${reconciliationFileDefaultName} failed`,
                errors: {
                    system: response,
                } as unknown as FileValidationErrors,
            },
        ];
    if (status === AsyncJobStatusEnum.inProgress)
        return [
            {
                uuid: jobId,
                updatedAt: createdAt,
                filename: `${reconciliationFileDefaultName} is in progress`,
                mimetype: excelMimeType,
                progress: 70,
            },
        ];
    return [
        {
            uuid: jobId,
            updatedAt: createdAt,
            filename: `${reconciliationFileDefaultName} sucessfully completed`,
            mimetype: excelMimeType,
        },
    ];
};
