import {
    AssortedBy,
    AssortmentClusterOptionCounts,
    AssortmentData,
    AssortmentDetails,
    AssortmentRecord,
    FilteringMetaData,
    LoadingStatus,
    Material,
    MaterialLockedForPartnerAssortment,
    AssortmentClusterAction,
} from 'buyplan-common';
import { maxBy } from 'lodash';
import {
    ADD_ASSORTMENT_RECORD,
    ADD_ASSORTMENT_RECORD_FAILURE,
    ADD_ASSORTMENT_RECORD_SUCCESS,
    GET_ASSORTMENT_META_SUCCESS,
    REMOVE_ASSORTMENT_RECORD,
    REMOVE_ASSORTMENT_RECORD_FAILURE,
    REMOVE_ASSORTMENT_RECORD_SUCCESS,
    SET_ASSORTMENT,
    UPDATE_ASSORTMENT_CLUSTER,
    UPDATE_ASSORTMENT_CLUSTER_FAILURE,
    UPDATE_ASSORTMENT_CLUSTER_SUCCESS,
    UPDATE_ASSORTMENT_MATERIAL,
    UPDATE_ASSORTMENT_MATERIAL_FAILURE,
    UPDATE_ASSORTMENT_MATERIAL_SUCCESS,
    UPDATE_CLUSTERS_ASSORTMENT_RECORDS,
    UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS,
} from '../actions/actionTypes';
import { AssortmentActions } from '../actions/assortment';

export interface AssortmentState {
    metaData?: FilteringMetaData;
    data: AssortmentData;
    loadingStatus?: LoadingStatus;
}

export const initialState: AssortmentState = {
    data: {
        clusterOptionCounts: [],
        materials: [],
        assortmentRecords: [],
        materialsLockedForPartnerAssortment: [],
    },
    loadingStatus: LoadingStatus.initial,
};

const updateStoreOptionCountByAssortmentRecords = (
    {
        action,
        clusterAssortmentStores,
        materialCode,
    }: {
        action: AssortmentClusterAction;
        clusterAssortmentStores: { clusterId?: string; storeId: string }[];
        materialCode: string;
    },
    clusterOptionCounts: AssortmentClusterOptionCounts[],
    assortmentRecords?: AssortmentDetails[]
) => {
    // filter clusters that need to be updated
    const clustersForUpdate: { [key: string]: string[] } = {};
    clusterAssortmentStores.forEach(({ clusterId = '', storeId }) => {
        if (!clustersForUpdate[clusterId]) clustersForUpdate[clusterId] = [];
        clustersForUpdate[clusterId].push(storeId);
    });
    const assortmentRecordsByClusterAndStore =
        assortmentRecords?.reduce((obj, record) => {
            const key = `${record.clusterId}_${record.storeId}`;
            return {
                ...obj,
                [key]: [...(obj[key] || []), record],
            };
        }, {} as { [key: string]: AssortmentRecord[] }) || {};
    const clusterIdsForUpdate = Object.keys(clustersForUpdate);

    return clusterOptionCounts.map((clusterOptionCount) => {
        const { clusterId, storeOptionCounts } = clusterOptionCount;
        if (!clusterIdsForUpdate.includes(clusterId)) return clusterOptionCount;
        // recalculate option count for cluster and cluster stores
        const storeIdsForUpdate = clustersForUpdate[clusterId];
        const newStoreOptionCounts = storeOptionCounts.map((storeOptionCount) => {
            const { storeId } = storeOptionCount;
            if (!storeIdsForUpdate.includes(storeId)) {
                return storeOptionCount;
            }
            // get assortment records for current cluster store combination
            const assortmentRecordsKey = `${clusterId}_${storeId}`;
            const clusterStoreAssortmentRecords = assortmentRecordsByClusterAndStore[assortmentRecordsKey] || [];
            // check is we already have such assortment record
            const makeChange = !(
                clusterStoreAssortmentRecords.some(
                    (clusterStoreAssortmentRecord) => clusterStoreAssortmentRecord.materialCode === materialCode
                ) && action === AssortmentClusterAction.ASSORT
            );

            if (!makeChange) {
                return storeOptionCount;
            }
            // recalculate if we don't have such assortment
            const newStoreOptionCount = {
                ...storeOptionCount,
                actualOptions:
                    action === AssortmentClusterAction.ASSORT
                        ? storeOptionCount.actualOptions + 1
                        : storeOptionCount.actualOptions - 1,
                filteredOptions:
                    action === AssortmentClusterAction.ASSORT
                        ? storeOptionCount.filteredOptions + 1
                        : storeOptionCount.filteredOptions - 1,
            };
            return newStoreOptionCount;
        });
        const newClusterOptionCount = {
            ...clusterOptionCount,
            storeOptionCounts: newStoreOptionCounts,
            filteredOptions: maxBy(newStoreOptionCounts, 'filteredOptions')?.filteredOptions || 0,
            actualOptions: maxBy(newStoreOptionCounts, 'actualOptions')?.actualOptions || 0,
        };
        return newClusterOptionCount;
    });
};

const updateClusterStoreOptionCount = (
    clusterOptionCounts: AssortmentClusterOptionCounts[],
    storeId: string,
    update: (cluster: AssortmentClusterOptionCounts, storeNumber: string) => AssortmentClusterOptionCounts,
    clusterId?: string
) => clusterOptionCounts.map((cluster) => (cluster.clusterId === clusterId ? update(cluster, storeId) : cluster));

const updateAssortment = (
    { storeNumber, materialCode }: AssortmentRecord,
    update: (store: AssortmentDetails) => AssortmentDetails,
    assortmentRecords?: AssortmentDetails[]
) => {
    if (!assortmentRecords) {
        return [];
    }
    return assortmentRecords.map((record) =>
        record.storeNumber === storeNumber && record.materialCode === materialCode
            ? update({
                  ...record,
                  assortedBy: AssortedBy.planner,
              })
            : record
    );
};

const updateMaterialsLockedForPartnerAssortment = (
    updatedMaterialLockRecordsForPartnerAssortment: MaterialLockedForPartnerAssortment[],
    materialLockRecordsForPartnerAssortment: MaterialLockedForPartnerAssortment[]
) => {
    const result = [...materialLockRecordsForPartnerAssortment];
    for (const updatedMaterialsLockRecord of updatedMaterialLockRecordsForPartnerAssortment) {
        const materialLockRecord = materialLockRecordsForPartnerAssortment.find(
            ({ materialCode, partner }) =>
                materialCode === updatedMaterialsLockRecord.materialCode && partner === updatedMaterialsLockRecord.partner
        );
        if (materialLockRecord) {
            materialLockRecord.isLocked = updatedMaterialsLockRecord.isLocked;
        } else {
            result.push(updatedMaterialsLockRecord);
        }
    }

    return result;
};
const updateClustersAssortmentRecords = (
    {
        materialCode,
        action,
        clusterAssortmentStores,
    }: {
        materialCode: string;
        action: AssortmentClusterAction;
        clusterAssortmentStores: { storeNumber: string; clusterId: string; storeId: string }[];
    },
    assortmentRecords: AssortmentDetails[] = []
) => {
    const recordsMap = new Map(assortmentRecords.map((record) => [`${record.materialCode}_${record.storeId}`, record]));

    for (const { storeId, storeNumber, clusterId } of clusterAssortmentStores) {
        const key = `${materialCode}_${storeId}`;
        if (action === AssortmentClusterAction.UNASSORT) {
            recordsMap.delete(key);
        } else if (action === AssortmentClusterAction.ASSORT) {
            recordsMap.set(key, {
                storeId,
                storeNumber,
                clusterId,
                materialCode,
                isFromMerchandiseFile: false,
                assortedBy: AssortedBy.planner,
            });
        }
    }

    return Array.from(recordsMap.values());
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: AssortmentState = initialState, action: AssortmentActions): AssortmentState => {
    switch (action.type) {
        case SET_ASSORTMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    materials: action.page > 0 ? [...state.data.materials, ...action.data.materials] : action.data.materials,
                    clusterOptionCounts: action.data.clusterOptionCounts,
                    assortmentRecords:
                        action.page > 0
                            ? [...state.data.assortmentRecords, ...action.data.assortmentRecords]
                            : action.data.assortmentRecords,
                    materialsLockedForPartnerAssortment: action.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.success,
            };
        case GET_ASSORTMENT_META_SUCCESS:
            return {
                ...state,
                metaData: action.metaData,
            };
        case UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT: {
            return {
                ...state,
                loadingStatus: LoadingStatus.loading,
            };
        }
        case UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS: {
            const { materialLockedForPartners } = action;
            return {
                ...state,
                data: {
                    clusterOptionCounts: state.data.clusterOptionCounts,
                    materials: state.data.materials,
                    assortmentRecords: state.data.assortmentRecords,
                    materialsLockedForPartnerAssortment: updateMaterialsLockedForPartnerAssortment(
                        materialLockedForPartners,
                        state.data.materialsLockedForPartnerAssortment
                    ),
                },
                loadingStatus: LoadingStatus.success,
            };
        }
        case UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE: {
            const { materialLockedForPartners } = action;
            const failedMaterialLockedForPartners = materialLockedForPartners.map((record) => ({
                ...record,
                isLocked: !record.isLocked,
            }));
            return {
                ...state,
                data: {
                    clusterOptionCounts: state.data.clusterOptionCounts,
                    materials: state.data.materials,
                    assortmentRecords: state.data.assortmentRecords,
                    materialsLockedForPartnerAssortment: updateMaterialsLockedForPartnerAssortment(
                        failedMaterialLockedForPartners,
                        state.data.materialsLockedForPartnerAssortment
                    ),
                },
                loadingStatus: LoadingStatus.error,
            };
        }
        case UPDATE_ASSORTMENT_CLUSTER: {
            const { materialCode, clusterAssortmentStores, action: assortmentAction } = action;

            return {
                ...state,
                data: {
                    clusterOptionCounts: updateStoreOptionCountByAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.clusterOptionCounts,
                        state.data.assortmentRecords
                    ),
                    assortmentRecords: updateClustersAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.assortmentRecords
                    ),
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.loading,
            };
        }
        case UPDATE_ASSORTMENT_CLUSTER_SUCCESS: {
            return {
                ...state,
                loadingStatus: LoadingStatus.success,
            };
        }
        case UPDATE_ASSORTMENT_CLUSTER_FAILURE: {
            const { materialCode, clusterAssortmentStores, action: assortmentAction } = action;
            return {
                ...state,
                data: {
                    clusterOptionCounts: updateStoreOptionCountByAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.clusterOptionCounts,
                        state.data.assortmentRecords
                    ),
                    assortmentRecords: updateClustersAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.assortmentRecords
                    ),
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.error,
            };
        }
        case UPDATE_CLUSTERS_ASSORTMENT_RECORDS: {
            return {
                ...state,
                loadingStatus: LoadingStatus.loading,
            };
        }
        case UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS: {
            const { materialCode, action: assortmentAction, clusterAssortmentStores } = action;

            return {
                ...state,
                data: {
                    clusterOptionCounts: updateStoreOptionCountByAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.clusterOptionCounts,
                        state.data.assortmentRecords
                    ),
                    assortmentRecords: updateClustersAssortmentRecords(
                        { action: assortmentAction, clusterAssortmentStores, materialCode },
                        state.data.assortmentRecords
                    ),

                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.success,
            };
        }
        case REMOVE_ASSORTMENT_RECORD: {
            const { clusterId, assortmentRecord } = action;
            const { storeId, storeNumber, materialCode } = assortmentRecord;
            return {
                ...state,
                data: {
                    clusterOptionCounts: updateClusterStoreOptionCount(
                        state.data.clusterOptionCounts,
                        storeId,
                        (cluster, storeOptionCountId) => {
                            const storeOptions = cluster.storeOptionCounts.map((storeOptionCount) =>
                                storeOptionCount.storeId === storeOptionCountId
                                    ? {
                                          ...storeOptionCount,
                                          actualOptions: storeOptionCount.actualOptions - 1,
                                          filteredOptions: storeOptionCount.filteredOptions - 1,
                                      }
                                    : storeOptionCount
                            );
                            return {
                                ...cluster,
                                actualOptions: maxBy(storeOptions, 'actualOptions')?.actualOptions || 0,
                                filteredOptions: maxBy(storeOptions, 'filteredOptions')?.filteredOptions || 0,
                                storeOptionCounts: storeOptions,
                            };
                        },
                        clusterId
                    ),
                    assortmentRecords: state.data.assortmentRecords
                        ? state.data.assortmentRecords.filter(
                              (record: AssortmentRecord) =>
                                  !(record.storeNumber === storeNumber && record.materialCode === materialCode)
                          )
                        : [],
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.loading,
            };
        }
        case REMOVE_ASSORTMENT_RECORD_SUCCESS: {
            return {
                ...state,
                data: {
                    assortmentRecords: updateAssortment(
                        action.assortmentRecord,
                        (record: AssortmentDetails) => ({
                            ...record,
                            isUpdating: false,
                        }),
                        state.data.assortmentRecords
                    ),
                    clusterOptionCounts: state.data.clusterOptionCounts,
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.success,
            };
        }
        case REMOVE_ASSORTMENT_RECORD_FAILURE: {
            const { clusterId, assortmentRecord } = action;
            const { storeId, storeNumber, materialCode } = assortmentRecord;
            return {
                ...state,
                data: {
                    assortmentRecords: state.data.assortmentRecords
                        ? [
                              ...state.data.assortmentRecords,
                              {
                                  storeId,
                                  storeNumber,
                                  materialCode,
                                  isFromMerchandiseFile: false,
                                  assortedBy: AssortedBy.planner,
                                  isUpdating: false,
                                  clusterId,
                              },
                          ]
                        : [],
                    clusterOptionCounts: updateClusterStoreOptionCount(
                        state.data.clusterOptionCounts,
                        storeId,
                        (cluster, storeOptionCountId) => {
                            const storeOptions = cluster.storeOptionCounts.map((storeOptionCount) =>
                                storeOptionCount.storeId === storeOptionCountId
                                    ? {
                                          ...storeOptionCount,
                                          actualOptions: storeOptionCount.actualOptions + 1,
                                          filteredOptions: storeOptionCount.filteredOptions + 1,
                                      }
                                    : storeOptionCount
                            );
                            return {
                                ...cluster,
                                actualOptions: maxBy(storeOptions, 'actualOptions')?.actualOptions || 0,
                                filteredOptions: maxBy(storeOptions, 'filteredOptions')?.filteredOptions || 0,
                                storeOptionCounts: storeOptions,
                            };
                        },
                        clusterId
                    ),
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.error,
            };
        }
        case ADD_ASSORTMENT_RECORD: {
            const { clusterId, assortmentRecord } = action;
            const { storeId, storeNumber, materialCode } = assortmentRecord;
            return {
                ...state,
                data: {
                    assortmentRecords: state.data.assortmentRecords
                        ? [
                              ...state.data.assortmentRecords,
                              {
                                  storeId,
                                  storeNumber,
                                  materialCode,
                                  isFromMerchandiseFile: false,
                                  assortedBy: AssortedBy.planner,
                                  isUpdating: true,
                                  clusterId,
                              },
                          ]
                        : [],
                    // ADD_ASSORTMENT_RECORD doesn't occur when assortment state is empty
                    clusterOptionCounts: updateClusterStoreOptionCount(
                        state.data.clusterOptionCounts,
                        storeId,
                        (cluster, storeOptionCountId) => {
                            const storeOptions = cluster.storeOptionCounts.map((storeOptionCount) =>
                                storeOptionCount.storeId === storeOptionCountId
                                    ? {
                                          ...storeOptionCount,
                                          actualOptions: storeOptionCount.actualOptions + 1,
                                          filteredOptions: storeOptionCount.filteredOptions + 1,
                                      }
                                    : storeOptionCount
                            );
                            return {
                                ...cluster,
                                actualOptions: maxBy(storeOptions, 'actualOptions')?.actualOptions || 0,
                                filteredOptions: maxBy(storeOptions, 'filteredOptions')?.filteredOptions || 0,
                                storeOptionCounts: storeOptions,
                            };
                        },
                        clusterId
                    ),
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.loading,
            };
        }
        case ADD_ASSORTMENT_RECORD_SUCCESS: {
            return {
                ...state,
                data: {
                    assortmentRecords: updateAssortment(
                        action.assortmentRecord,
                        (record: AssortmentDetails) => ({
                            ...record,
                            isUpdating: false,
                        }),
                        state.data.assortmentRecords
                    ),
                    clusterOptionCounts: state.data.clusterOptionCounts,
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.success,
            };
        }
        case ADD_ASSORTMENT_RECORD_FAILURE: {
            const { clusterId, assortmentRecord } = action;
            const { storeNumber, storeId, materialCode } = assortmentRecord;
            return {
                ...state,
                data: {
                    assortmentRecords: state.data.assortmentRecords
                        ? state.data.assortmentRecords.filter(
                              (record: AssortmentRecord) =>
                                  !(record.storeNumber === storeNumber && record.materialCode === materialCode)
                          )
                        : [],
                    clusterOptionCounts: updateClusterStoreOptionCount(
                        state.data.clusterOptionCounts,
                        storeId,
                        (cluster, storeOptionCountId) => {
                            const storeOptions = cluster.storeOptionCounts.map((storeOptionCount) =>
                                storeOptionCount.storeId === storeOptionCountId
                                    ? {
                                          ...storeOptionCount,
                                          actualOptions: storeOptionCount.actualOptions - 1,
                                          filteredOptions: storeOptionCount.filteredOptions - 1,
                                      }
                                    : storeOptionCount
                            );
                            return {
                                ...cluster,
                                actualOptions: maxBy(storeOptions, 'actualOptions')?.actualOptions || 0,
                                filteredOptions: maxBy(storeOptions, 'filteredOptions')?.filteredOptions || 0,
                                storeOptionCounts: storeOptions,
                            };
                        },
                        clusterId
                    ),
                    materials: state.data.materials,
                    materialsLockedForPartnerAssortment: state.data.materialsLockedForPartnerAssortment,
                },
                loadingStatus: LoadingStatus.error,
            };
        }
        case UPDATE_ASSORTMENT_MATERIAL: {
            const { materialId, materialAttributes } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    materials: state.data.materials.map((material: Material) =>
                        material.id === materialId ? { ...material, ...materialAttributes } : material
                    ),
                },
                loadingStatus: LoadingStatus.loading,
            };
        }
        case UPDATE_ASSORTMENT_MATERIAL_SUCCESS: {
            return {
                ...state,
                loadingStatus: LoadingStatus.success,
            };
        }
        case UPDATE_ASSORTMENT_MATERIAL_FAILURE: {
            const { materialId, material: oldMaterial } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    materials: state.data.materials.map((material: Material) =>
                        material.id === materialId ? oldMaterial : material
                    ),
                },
                loadingStatus: LoadingStatus.error,
            };
        }
        default:
            return state;
    }
};
