import {
    AssortmentDetails,
    AssortmentClusterOptionCounts,
    AssortmentStoreOptionCounts,
    canUpdateDigitalBuyplan,
    ChannelRole,
    Material,
    AssortmentClusterAction,
} from 'buyplan-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loader from '../Loader/Loader';
import { updateClustersAssortmentRecords } from '../../actions/assortment';
import { useSelector } from '../../store/reducers';
import './AssortmentDetailCheckbox.scss';
import useHasChannelRole from '../../selectors/useHasChannelRole';

export interface Props {
    assortmentRecords: AssortmentDetails[];
    clusterOptionCounts: AssortmentClusterOptionCounts[];
    material: Material;
    assortAllLoading: boolean;
    setAssortAllLoading: (loading: boolean) => void;
}

enum CheckboxState {
    All,
    Partial,
    None,
}

function AssortmentCheckAllCheckbox({
    clusterOptionCounts,
    assortmentRecords,
    material,
    assortAllLoading,
    setAssortAllLoading,
}: Props) {
    const dispatch = useDispatch();
    const allStoreOptionCounts = clusterOptionCounts
        .filter(({ clusterId }) => clusterId !== 'mock_cluster_id')
        .flatMap(({ storeOptionCounts }) => storeOptionCounts)
        .filter(({ categoryDivisionPairs }) =>
            categoryDivisionPairs.find(
                ({ category, division }: { category: string; division: string }) =>
                    category === material.category && division === material.division
            )
        );

    const recordsWithinClusters = assortmentRecords.filter(
        ({ materialCode, clusterId }) => materialCode === material.materialCode && clusterId !== 'mock_cluster_id'
    );

    const { activeChannelId } = useSelector(({ user }) => user.settings);
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);
    const clustersNotEnabled = clusterOptionCounts.length === 1 && clusterOptionCounts[0].clusterId === 'mock_cluster_id';

    const filterIPPStores = (storeNumber: string) =>
        canUpdateDigitalBuyplan(activeChannelId, storeNumber, material.typeOfOrder);

    let checkboxValue: CheckboxState;
    if (recordsWithinClusters.length === 0) {
        checkboxValue = CheckboxState.None;
    } else if (allStoreOptionCounts.length === recordsWithinClusters.length) {
        checkboxValue = CheckboxState.All;
    } else {
        checkboxValue = CheckboxState.Partial;
    }

    const handleChange = async () => {
        const action =
            checkboxValue !== CheckboxState.All ? AssortmentClusterAction.ASSORT : AssortmentClusterAction.UNASSORT;
        const clusters = clusterOptionCounts
            .map((clusterOptionCount) => ({
                ...clusterOptionCount,
                storeOptionCounts: clusterOptionCount.storeOptionCounts
                    .filter(({ categoryDivisionPairs }: AssortmentStoreOptionCounts) =>
                        categoryDivisionPairs.find(
                            ({ category, division }: { category: string; division: string }) =>
                                category === material.category && division === material.division
                        )
                    )
                    .filter(({ number }: AssortmentStoreOptionCounts) => {
                        if (action === AssortmentClusterAction.ASSORT) {
                            for (let i = 0; i < assortmentRecords.length; i++) {
                                const assortmentRecord = assortmentRecords[i];
                                if (
                                    assortmentRecord.storeNumber === number &&
                                    assortmentRecord.materialCode === material.materialCode
                                ) {
                                    return false;
                                }
                            }
                        }
                        return filterIPPStores(number);
                    })
                    .filter(({ number }: AssortmentStoreOptionCounts) => filterIPPStores(number)),
            }))
            .filter(({ storeOptionCounts }) => storeOptionCounts.length > 0);

        const clusterAssortmentStores = clusters.reduce((acc, { storeOptionCounts, clusterId }) => {
            if (clusterId === 'mock_cluster_id') return acc;
            return [
                ...acc,
                ...storeOptionCounts.map(({ storeId, number }: AssortmentStoreOptionCounts) => ({
                    storeId,
                    clusterId,
                    storeNumber: number,
                })),
            ];
        }, [] as { storeNumber: string; storeId: string; clusterId: string }[]);

        if (clusterAssortmentStores.length > 0) {
            setAssortAllLoading(true);
            await dispatch(
                updateClustersAssortmentRecords({
                    channelId: activeChannelId,
                    action,
                    materialCode: material.materialCode,
                    clusterAssortmentStores,
                })
            );
            setAssortAllLoading(false);
        }
    };

    let checkboxIcon: JSX.Element | null;
    if (clustersNotEnabled) {
        checkboxIcon = null;
    } else if (checkboxValue === CheckboxState.All) {
        checkboxIcon = (
            <FontAwesomeIcon
                className="AssortmentDetailCheckbox__allCheckCircle"
                size="2x"
                icon={faCheckCircle as IconProp}
            />
        );
    } else if (checkboxValue === CheckboxState.None) {
        checkboxIcon = null;
    } else {
        checkboxIcon = (
            <FontAwesomeIcon className="AssortmentDetailCheckbox__allCheckMinus" size="2x" icon={faMinus as IconProp} />
        );
    }

    return (
        <div
            className={cn('AssortmentDetailCheckbox', 'AssortmentDetailCheckbox__allCheck', {
                'AssortmentDetailCheckbox__allCheck--disabled': isReadOnlyUser || clustersNotEnabled,
            })}
        >
            {assortAllLoading ? (
                <Loader width={37} />
            ) : (
                <button type="button" onClick={handleChange} disabled={isReadOnlyUser || clustersNotEnabled}>
                    {checkboxIcon}
                </button>
            )}
        </div>
    );
}

export default AssortmentCheckAllCheckbox;
