"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateAaInvestUnits = exports.calculateInvestUnits = exports.calculateEndOfPeriodUnits = exports.calculateAfSalesUnits = exports.calculateAaSalesUnits = exports.calculateSalesUnits = exports.canUpdateDigitalBuyplan = exports.distributeOpenToBuyUnitsPerAggregatedMaterial = exports.distributeOpenToBuyUnits = exports.calculateAggregatedMaterialOpenToBuyUnits = exports.calculateAaOpenToBuyUnits = exports.calculateAfOpenToBuyUnits = exports.reCalculateAFPercentage = exports.calculateOpenToBuyUnits = exports.calculateUpdatedSellThrough = exports.calculateSellThrough = exports.calculateAfPresentationStocks = exports.calculateAaPresentationStocks = exports.calculatePresentationStocks = void 0;
const lodash_1 = require("lodash");
const typeOfOrder_1 = require("../configs/typeOfOrder");
const channels_1 = require("../configs/channels");
const MAX_SELL_THROUGH_DECIMALS = 8;
const calculatePresentationStocks = (rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy) => {
    if (noSeasonalBuy) {
        return Math.round(rateOfSale * weeksOnSale);
    }
    if (sellThrough === 0) {
        return 0;
    }
    return Math.round((rateOfSale * weeksOnSale) / sellThrough);
};
exports.calculatePresentationStocks = calculatePresentationStocks;
const calculateAaPresentationStocks = ({ rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy, }, typeOfOrder) => typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.alwaysAvailable
    ? 0
    : (0, exports.calculatePresentationStocks)(rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy);
exports.calculateAaPresentationStocks = calculateAaPresentationStocks;
const calculateAfPresentationStocks = ({ rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy, }, typeOfOrder) => typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.authorizedFutures && typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.quickStrike
    ? 0
    : (0, exports.calculatePresentationStocks)(rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy);
exports.calculateAfPresentationStocks = calculateAfPresentationStocks;
const calculateSellThrough = (presentationStocks, rateOfSale, weeksOnSale) => {
    if (presentationStocks === 0) {
        return 0;
    }
    return (0, lodash_1.round)((rateOfSale * weeksOnSale) / presentationStocks, MAX_SELL_THROUGH_DECIMALS);
};
exports.calculateSellThrough = calculateSellThrough;
/**
 * Calculates the sellThrough that will be used to get the appropriate number of otb units for this store.
 */
const calculateUpdatedSellThrough = (beginningOfPeriodUnits, otbUnits, rateOfSale, weeksOnSale) => {
    const buy = otbUnits + beginningOfPeriodUnits;
    return (0, exports.calculateSellThrough)(buy, rateOfSale, weeksOnSale);
};
exports.calculateUpdatedSellThrough = calculateUpdatedSellThrough;
const calculateOpenToBuyUnits = (beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId) => {
    if (noSeasonalBuy) {
        return 0;
    }
    const presentationStocks = (0, exports.calculatePresentationStocks)(rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy);
    if (channelId === channels_1.channels.digital.id || channelId === channels_1.channels.nvs.id) {
        const openToBuyUnits = presentationStocks - beginningOfPeriodUnits;
        return openToBuyUnits < 0 ? 0 : openToBuyUnits;
    }
    const salesUnits = rateOfSale * weeksOnSale;
    const openToBuyUnits = beginningOfPeriodUnits >= presentationStocks - salesUnits ? salesUnits : presentationStocks - beginningOfPeriodUnits;
    if (openToBuyUnits < 0)
        return 0;
    return (0, lodash_1.round)(openToBuyUnits);
};
exports.calculateOpenToBuyUnits = calculateOpenToBuyUnits;
/*
    AF OTB and AA OTB values are calculated based on AF % and must be whole numbers.
    Thats why when you change AF %, it will be related to AF OTB and AA OTB.
    In the case when units can't be whole numbers, AA OTB will be rounded up and AF OTB will be
    rounded down and based on this units you will have new AF %.
*/
const reCalculateAFPercentage = ({ afPercentage, currentBuy, channelId, }) => {
    const OTB = (0, exports.calculateOpenToBuyUnits)(currentBuy.beginningOfPeriodUnits, currentBuy.sellThrough, currentBuy.rateOfSale, currentBuy.weeksOnSale, currentBuy.noSeasonalBuy, channelId);
    if (OTB === 0)
        return afPercentage;
    const afOTB = Math.floor(OTB * afPercentage);
    return (0, lodash_1.round)(afOTB / OTB, 2);
};
exports.reCalculateAFPercentage = reCalculateAFPercentage;
const calculateAfOpenToBuyUnits = ({ beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, typeOfOrder, afPercentage, channelId, }) => {
    if (typeOfOrder === typeOfOrder_1.typeOfOrderOptions.nikeByYou)
        return 0;
    const openToBuyUnits = (0, exports.calculateOpenToBuyUnits)(beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId);
    const afOpenToBuyUnits = (0, lodash_1.round)(openToBuyUnits * afPercentage);
    const aaOpenToBuyUnits = (0, lodash_1.round)(openToBuyUnits * (1 - afPercentage));
    return afOpenToBuyUnits + aaOpenToBuyUnits === openToBuyUnits ? afOpenToBuyUnits : afOpenToBuyUnits - 1;
};
exports.calculateAfOpenToBuyUnits = calculateAfOpenToBuyUnits;
const calculateAaOpenToBuyUnits = ({ beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId, typeOfOrder, afPercentage, }) => {
    if (typeOfOrder === typeOfOrder_1.typeOfOrderOptions.nikeByYou)
        return 0;
    return ((0, exports.calculateOpenToBuyUnits)(beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId) -
        (0, exports.calculateAfOpenToBuyUnits)({
            beginningOfPeriodUnits,
            sellThrough,
            rateOfSale,
            weeksOnSale,
            noSeasonalBuy,
            channelId,
            typeOfOrder,
            afPercentage,
        }));
};
exports.calculateAaOpenToBuyUnits = calculateAaOpenToBuyUnits;
const calculateAggregatedMaterialOpenToBuyUnits = (buys, channelId) => (0, lodash_1.sum)(buys.map((buy) => (0, exports.calculateOpenToBuyUnits)(buy.beginningOfPeriodUnits, buy.sellThrough, buy.rateOfSale, buy.weeksOnSale, buy.noSeasonalBuy, channelId)));
exports.calculateAggregatedMaterialOpenToBuyUnits = calculateAggregatedMaterialOpenToBuyUnits;
const distributeOpenToBuyUnits = (aggregatedMaterialBuys, targetOpenToBuyUnits, channelId) => {
    const buysToIgnore = aggregatedMaterialBuys.filter((buy) => buy.rateOfSale === 0 ||
        buy.weeksOnSale === 0 ||
        buy.noSeasonalBuy ||
        buy.beginningOfPeriodUnits >=
            (buy.rateOfSale * buy.weeksOnSale) / buy.sellThrough - buy.rateOfSale * buy.weeksOnSale);
    const buysToInclude = aggregatedMaterialBuys.filter((buy) => buy.rateOfSale > 0 &&
        buy.weeksOnSale > 0 &&
        buy.noSeasonalBuy === false &&
        buy.beginningOfPeriodUnits <
            (buy.rateOfSale * buy.weeksOnSale) / buy.sellThrough - buy.rateOfSale * buy.weeksOnSale);
    // When decreasing the total OTB units of a set of aggregatedMaterials, it can be that the total cannot be met.
    // We loop through all aggregatedMaterials, to try to apply this difference.
    // It can be that "current OTB units" - "difference" is lower than 0.
    // If so, we try to go as low as possible, without setting the ROS to 0.
    // We get this value by setting the ST to 100%.
    if (targetOpenToBuyUnits < 0) {
        return [...buysToIgnore, ...buysToInclude.map((buy) => ({ ...buy, sellThrough: 1 }))];
    }
    const buysWithOpenToBuyUnits = buysToInclude.map((buy) => ({
        ...buy,
        openToBuyUnits: (0, exports.calculateOpenToBuyUnits)(buy.beginningOfPeriodUnits, buy.sellThrough, buy.rateOfSale, buy.weeksOnSale, buy.noSeasonalBuy, channelId),
    }));
    const currentOpenToBuyUnits = (0, lodash_1.sumBy)(buysWithOpenToBuyUnits, 'openToBuyUnits');
    const change = targetOpenToBuyUnits / currentOpenToBuyUnits;
    const adjustedBuys = buysWithOpenToBuyUnits
        // Calculate OTB units, ignoring fractional units
        .map((buy) => ({ ...buy, newOpenToBuyUnits: Math.floor(buy.openToBuyUnits * change) }))
        // If 0 OTB units, set ROS to 0 and leave ST%
        .map((buy) => ({
        ...buy,
        rateOfSale: buy.newOpenToBuyUnits === 0 ? 0 : buy.rateOfSale,
    }))
        // Adjust ST% with change
        .map((buy) => ({
        ...buy,
        sellThrough: (0, exports.calculateUpdatedSellThrough)(buy.beginningOfPeriodUnits, buy.newOpenToBuyUnits, buy.rateOfSale, buy.weeksOnSale),
    }))
        // ST% cannot be less than 0.05 (0.5%)
        .map((buy) => ({ ...buy, sellThrough: buy.sellThrough < 0.005 ? 0.005 : buy.sellThrough }))
        // ST% cannot be more than 1 (100%)
        .map((buy) => ({ ...buy, sellThrough: buy.sellThrough > 1 ? 1 : buy.sellThrough }))
        // Recalculate OTB, with new ST% or ROS
        .map((buy) => ({
        ...buy,
        newOpenToBuyUnits: (0, exports.calculateOpenToBuyUnits)(buy.beginningOfPeriodUnits, buy.sellThrough, buy.rateOfSale, buy.weeksOnSale, buy.noSeasonalBuy, channelId),
    }));
    let aggregatedMaterialAdjustedOpenToBuyUnits = (0, lodash_1.sumBy)(adjustedBuys, 'newOpenToBuyUnits');
    let roundingDiff = aggregatedMaterialAdjustedOpenToBuyUnits - targetOpenToBuyUnits;
    if (roundingDiff !== 0) {
        const orderedStores = (0, lodash_1.orderBy)(adjustedBuys, 'newOpenToBuyUnits', 'desc');
        for (const store of orderedStores) {
            // Ignore stores that cannot be changed
            if (store.rateOfSale !== 0 &&
                store.noSeasonalBuy === false &&
                store.weeksOnSale !== 0 &&
                store.beginningOfPeriodUnits <
                    (store.rateOfSale * store.weeksOnSale) / store.sellThrough - store.rateOfSale * store.weeksOnSale) {
                const newOpenToBuyUnits = (0, exports.calculateOpenToBuyUnits)(store.beginningOfPeriodUnits, store.sellThrough, store.rateOfSale, store.weeksOnSale, store.noSeasonalBuy, channelId) - roundingDiff;
                // If it needs to get rid of more than it can handle just set it to smallest OTB units, which is reached by ST of 100%
                if (newOpenToBuyUnits <= 0) {
                    store.sellThrough = 1;
                }
                else {
                    store.sellThrough = (0, exports.calculateUpdatedSellThrough)(store.beginningOfPeriodUnits, newOpenToBuyUnits, store.rateOfSale, store.weeksOnSale);
                }
                if (store.sellThrough < 0.005) {
                    store.sellThrough = 0.005;
                }
                if (store.sellThrough > 1) {
                    store.sellThrough = 1;
                }
                aggregatedMaterialAdjustedOpenToBuyUnits = (0, lodash_1.sumBy)(adjustedBuys, 'newOpenToBuyUnits');
                roundingDiff = aggregatedMaterialAdjustedOpenToBuyUnits - targetOpenToBuyUnits;
                if (roundingDiff === 0) {
                    break;
                }
            }
        }
    }
    return [...adjustedBuys, ...buysToIgnore].map((buy) => (0, lodash_1.omit)(buy, ['openToBuyUnits', 'newOpenToBuyUnits', 'oldRateOfSales']));
};
exports.distributeOpenToBuyUnits = distributeOpenToBuyUnits;
const distributeOpenToBuyUnitsPerAggregatedMaterial = (buys, targetTotalOpenToBuyUnits, channelId) => {
    const currentOpenToBuyUnits = (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(buys, channelId);
    const change = targetTotalOpenToBuyUnits / currentOpenToBuyUnits;
    const buysByMaterialAndPartner = (0, lodash_1.groupBy)(buys, (buy) => `${buy.partnerId}_${buy.materialId}`);
    let adjustedTotal = 0;
    for (const aggregatedMaterialKey of Object.keys(buysByMaterialAndPartner)) {
        const aggregatedMaterialBuys = buysByMaterialAndPartner[aggregatedMaterialKey];
        const aggregatedMaterialOpenToBuyUnits = (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(aggregatedMaterialBuys, channelId);
        const newAggregatedMaterialOpenToBuyUnits = Math.floor(aggregatedMaterialOpenToBuyUnits * change);
        const adjustedAggregatedMaterial = (0, exports.distributeOpenToBuyUnits)(aggregatedMaterialBuys, newAggregatedMaterialOpenToBuyUnits, channelId);
        const aggregatedMaterialAdjustedOpenToBuyUnits = (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(adjustedAggregatedMaterial, channelId);
        adjustedTotal += aggregatedMaterialAdjustedOpenToBuyUnits;
        buysByMaterialAndPartner[aggregatedMaterialKey] = adjustedAggregatedMaterial;
    }
    let roundingDiff = targetTotalOpenToBuyUnits - adjustedTotal;
    if (roundingDiff !== 0) {
        // Sort by largest aggregatedMaterial first
        const orderedAggregatedMaterialKeys = Object.keys(buysByMaterialAndPartner).sort((keyA, keyB) => (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(buysByMaterialAndPartner[keyB], channelId) -
            (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(buysByMaterialAndPartner[keyA], channelId));
        for (const aggregatedMaterialKey of orderedAggregatedMaterialKeys) {
            const aggregatedMaterial = buysByMaterialAndPartner[aggregatedMaterialKey];
            const aggregatedMaterialOpenToBuyUnits = (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(aggregatedMaterial, channelId);
            const targetAggregatedMaterialOpenToBuyUnits = aggregatedMaterialOpenToBuyUnits + roundingDiff;
            const adjustedAggregatedMaterial = (0, exports.distributeOpenToBuyUnits)(aggregatedMaterial, targetAggregatedMaterialOpenToBuyUnits, channelId);
            const adjustedAggregatedMaterialOpenToBuyUnits = (0, exports.calculateAggregatedMaterialOpenToBuyUnits)(adjustedAggregatedMaterial, channelId);
            buysByMaterialAndPartner[aggregatedMaterialKey] = adjustedAggregatedMaterial;
            roundingDiff -= adjustedAggregatedMaterialOpenToBuyUnits - aggregatedMaterialOpenToBuyUnits;
            if (roundingDiff === 0) {
                break;
            }
        }
    }
    return (0, lodash_1.values)(buysByMaterialAndPartner).flat();
};
exports.distributeOpenToBuyUnitsPerAggregatedMaterial = distributeOpenToBuyUnitsPerAggregatedMaterial;
/**
 *
 * canUpdateDigitalBuyplan checks for any constraint which may retrict update for a aggregatedMaterial.
 *
 * @returns  true or false depending on the constraint conditions.
 */
const canUpdateDigitalBuyplan = (channelId, storeNumber, typeOfOrder) => {
    if (channelId === channels_1.channels.digital.id && storeNumber === 'IPP' && typeOfOrder === typeOfOrder_1.typeOfOrderOptions.nikeByYou) {
        return false;
    }
    return true;
};
exports.canUpdateDigitalBuyplan = canUpdateDigitalBuyplan;
const calculateSalesUnits = (weeksOnSale, rateOfSale = 0) => Math.round(rateOfSale * weeksOnSale);
exports.calculateSalesUnits = calculateSalesUnits;
const calculateAaSalesUnits = ({ weeksOnSale, rateOfSale = 0 }, typeOfOrder) => (typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.alwaysAvailable ? 0 : (0, exports.calculateSalesUnits)(weeksOnSale, rateOfSale));
exports.calculateAaSalesUnits = calculateAaSalesUnits;
const calculateAfSalesUnits = ({ weeksOnSale, rateOfSale = 0 }, typeOfOrder) => typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.authorizedFutures && typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.quickStrike
    ? 0
    : (0, exports.calculateSalesUnits)(weeksOnSale, rateOfSale);
exports.calculateAfSalesUnits = calculateAfSalesUnits;
const calculateEndOfPeriodUnits = (beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId, typeOfOrder) => {
    if (typeOfOrder === typeOfOrder_1.typeOfOrderOptions.alwaysAvailable ||
        typeOfOrder === typeOfOrder_1.typeOfOrderOptions.authorizedFutures ||
        typeOfOrder === typeOfOrder_1.typeOfOrderOptions.quickStrike) {
        const salesUnits = (0, exports.calculateSalesUnits)(weeksOnSale, rateOfSale);
        const openToBuyUnits = (0, exports.calculateOpenToBuyUnits)(beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId);
        const EOP = !noSeasonalBuy ? openToBuyUnits + beginningOfPeriodUnits - salesUnits : 0;
        return EOP < 0 ? 0 : EOP;
    }
    return 0;
};
exports.calculateEndOfPeriodUnits = calculateEndOfPeriodUnits;
const calculateInvestUnits = ({ beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId, }) => (0, exports.calculateOpenToBuyUnits)(beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId) +
    beginningOfPeriodUnits;
exports.calculateInvestUnits = calculateInvestUnits;
const calculateAaInvestUnits = ({ beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, channelId, typeOfOrder, }) => typeOfOrder !== typeOfOrder_1.typeOfOrderOptions.alwaysAvailable
    ? 0
    : (0, exports.calculateInvestUnits)({
        beginningOfPeriodUnits,
        sellThrough,
        rateOfSale,
        weeksOnSale,
        noSeasonalBuy,
        channelId,
    });
exports.calculateAaInvestUnits = calculateAaInvestUnits;
