import React from 'react';
import cn from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import Buyplan from '../components/Buyplan/Buyplan';
import BuyplanDocumentation from '../components/Documentation/BuyplanDocumentation/BuyplanDocumentation';
import DummyCodes from '../components/DummyCodes/DummyCodes';
import EditSeason from '../components/EditSeason/EditSeason';
import ExchangeRatesView from '../components/ExchangeRates/ExchangeRatesView';
import ExchangeRatesAdd from '../components/ExchangeRates/ExchangeRatesAdd';
import ExchangeRatesEdit from '../components/ExchangeRates/ExchangeRatesEdit';
import ExportAssortment from '../components/ExportAssortment/ExportAssortment';
import ExportBuyPlan from '../components/ExportBuyPlan/ExportBuyPlan';
import ImportAssortmentFiles from '../components/ImportAssortmentFiles/ImportAssortmentFiles';
import ImportBuyplanFiles from '../components/ImportBuyplanFiles/ImportBuyplanFiles';
import LateAdds from '../components/LateAdds/LateAdds';
import ViewAssortment from '../components/ViewAssortment/ViewAssortment';
import NewSeason from '../components/NewSeason/NewSeason';
import SalesTargets from '../components/SalesTargets/SalesTargets';
import UserForm from '../components/UserForm/UserForm';
import UserList from '../components/UserList/UserList';
import ViewBuyplan from '../components/ViewBuyplan/ViewBuyplan';
import TopStylesReportPage from '../components/Reports/TopStylesReportPage/TopStylesReportPage';
import MarginImpact from '../components/MarginImpact/MarginImpact';
import './BuyplanRoutes.scss';
import useImpersonationStatus from '../selectors/useImpersonationStatus';
import DynamicRollups from '../components/DynamicRollups/DynamicRollups';
import Reconciliation from '../components/Reconciliation/Reconciliation';
import FeatureFlagGuard from '../components/FeatureFlags/FeatureFlagGuard';

function ReconciliationWithGuard() {
    return (
        <FeatureFlagGuard featureKey="isReconciliationEnabled">
            <Reconciliation />
        </FeatureFlagGuard>
    );
}

export default function () {
    const { impersonationInProgress } = useImpersonationStatus();
    const isProduction = window.location.hostname === 'buyplan.dsmplanning.nikecloud.com';
    return (
        <div
            className={cn('buyplan-routes', {
                'buyplan-routes--withImpersonationBanner': impersonationInProgress && isProduction,
                'buyplan-routes--withTestEnvBanner': !isProduction && !impersonationInProgress,
                'buyplan-routes--bothBanners': !isProduction && impersonationInProgress,
            })}
        >
            <Switch>
                <SecureRoute exact path="/buyplan" component={Buyplan} />
                <SecureRoute exact path="/buyplan/new-season" component={NewSeason} />
                <SecureRoute exact path="/buyplan/edit-season" component={EditSeason} />
                <SecureRoute exact path="/buyplan/import-files" component={ImportBuyplanFiles} />
                <SecureRoute exact path="/buyplan/view-buy-plan" component={ViewBuyplan} />
                <SecureRoute exact path="/buyplan/manage-assortment/import" component={ImportAssortmentFiles} />
                <SecureRoute exact path="/buyplan/manage-assortment/late-adds" component={LateAdds} />
                <SecureRoute exact path="/buyplan/manage-assortment/dummy-codes" component={DummyCodes} />
                <SecureRoute exact path="/buyplan/manage-assortment" component={ViewAssortment} />
                <SecureRoute exact path="/buyplan/manage-users/edit/:email" component={UserForm} />
                <SecureRoute exact path="/buyplan/manage-users/edit" component={UserForm} />
                <SecureRoute exact path="/buyplan/manage-users" component={UserList} />
                <SecureRoute exact path="/buyplan/export-assortment" component={ExportAssortment} />
                <SecureRoute exact path="/buyplan/export-buy-plan" component={ExportBuyPlan} />
                <SecureRoute exact path="/buyplan/dynamic-rollups" component={DynamicRollups} />
                <SecureRoute exact path="/buyplan/reconciliation" component={ReconciliationWithGuard} />
                <SecureRoute exact path="/buyplan/top-styles-report" component={TopStylesReportPage} />
                <SecureRoute exact path="/buyplan/sales-targets" component={SalesTargets} />
                <SecureRoute exact path="/buyplan/margin-impact" component={MarginImpact} />
                <Route exact path="/buyplan/exchange-rates" component={ExchangeRatesView} />
                <SecureRoute exact path="/buyplan/exchange-rates/add" component={ExchangeRatesAdd} />
                <SecureRoute exact path="/buyplan/exchange-rates/edit" component={ExchangeRatesEdit} />
                <Route path="/buyplan/documentation" component={BuyplanDocumentation} />
            </Switch>
        </div>
    );
}
