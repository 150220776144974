import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../api/useRequest';
import { getFeatureFlags } from '../../services/featureFlagsService';
import { setFeatureFlagsAction } from '../../actions/featureFlags';
import Loader from '../Loader/Loader';

export interface FeatureFlagsProps {
    children: ReactElement;
}

function FeatureFlagsProvider({ children }: FeatureFlagsProps): ReactElement {
    const [featureFlagsLoading, , fetchFeatureData] = useRequest(getFeatureFlags);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFeatureFlagsAction(fetchFeatureData as { [key: string]: boolean }));
    }, [fetchFeatureData, dispatch]);

    if (featureFlagsLoading) return <Loader />;

    return children;
}

export default FeatureFlagsProvider;
