import {
    BuyPlanAggregatedMaterial,
    BuyPlanStore,
    canUpdateDigitalBuyplan,
    calculateOpenToBuyUnits,
    calculatePresentationStocks,
    calculateSalesUnits,
    channels,
    calculateEndOfPeriodUnits,
    calculateAfOpenToBuyUnits,
    calculateAaOpenToBuyUnits,
    calculateUpdatedSellThrough,
} from 'buyplan-common';
import { round, floor, min, maxBy, meanBy, sumBy, orderBy } from 'lodash';

export const calculateBuy = (sellThrough: number, weeksOnSale: number, rateOfSale: number) => {
    if (sellThrough === 0) {
        return 0;
    }
    return Math.round((rateOfSale * weeksOnSale) / sellThrough);
};

export const distributeBeginningOfPeriodUnits = (
    aggregatedMaterialStores: BuyPlanStore[],
    inputBeginningOfPeriod: number
) => {
    const storeBOPShare = Math.floor(inputBeginningOfPeriod / aggregatedMaterialStores.length);
    let remainingBOPUnits = inputBeginningOfPeriod % aggregatedMaterialStores.length;
    const sortedStores = orderBy(aggregatedMaterialStores, 'rateOfSale', 'desc');

    const stores = sortedStores.map((store) => {
        let beginningOfPeriodUnits = storeBOPShare;
        if (remainingBOPUnits) {
            beginningOfPeriodUnits += 1;
            remainingBOPUnits--;
        }
        return { ...store, beginningOfPeriodUnits };
    });

    return stores;
};

export const distributeRateOfSale = (aggregatedMaterial: BuyPlanAggregatedMaterial, newAggregatedMaterialROS: number) => {
    const previousAggregatedMaterialROS = meanBy(aggregatedMaterial.stores, 'rateOfSale'); // Use real value, not the rounded aggregatedMaterial ROS
    if (previousAggregatedMaterialROS === 0) {
        return aggregatedMaterial.stores.map((store) => ({ ...store, rateOfSale: newAggregatedMaterialROS }));
    }
    const changePercentage = (newAggregatedMaterialROS - previousAggregatedMaterialROS) / previousAggregatedMaterialROS + 1;
    const updatedStores = aggregatedMaterial.stores.map((store) => ({
        ...store,
        rateOfSale: round(store.rateOfSale * changePercentage, 2),
    }));

    // Due to the rounding there can be a difference between what the user entered. If it's different
    // simply change the max store ROS.
    const roundingDifference = newAggregatedMaterialROS - meanBy(updatedStores, 'rateOfSale');
    if (Math.abs(roundingDifference) > 0.0049999999) {
        const maxStore = maxBy(updatedStores, 'rateOfSale');
        if (maxStore) {
            maxStore.rateOfSale += roundingDifference * updatedStores.length;
        }
    }

    return updatedStores;
};

const getMinimumBuyTooLow = (
    minimumBuyMaterial: number | null,
    allocationUnits: number | null,
    openToBuyUnits: number | null
): number => {
    if (openToBuyUnits === null) {
        return 0;
    }
    if (minimumBuyMaterial !== null && minimumBuyMaterial > openToBuyUnits) {
        return 1;
    }
    if (allocationUnits !== null && allocationUnits > openToBuyUnits) {
        return 1;
    }
    return 0;
};

/**
 * Given an array of store level buy-lines (ros / wos / st), this function
 * will return a new object with the aggregatedMaterial level:
 * - rateOfSale (avg)
 * - weeksOnSale (range)
 * - sellThrough (range)
 * - afPercentage (range)
 * - presentationStocks (sum)
 * - salesUnits (sum)
 * - openToB (sum)
 * - minimumBuyTooLow (sum)
 * - noSeasonalBuy (from first store, should all be equal)
 */
export const getAggregatedMaterialLevelBuyPlan = (stores: BuyPlanStore[], typeOfOrder: string, channelId: number) => {
    const aggregatedMaterialWithFilteredStores = stores.filter(({ storeNumber }) =>
        canUpdateDigitalBuyplan(channelId, storeNumber, typeOfOrder)
    );
    const rateOfSale = round(meanBy(aggregatedMaterialWithFilteredStores, 'rateOfSale'), 2);
    const weeksOnSale = meanBy(stores, 'weeksOnSale');
    const sellThrough = round(meanBy(stores, 'sellThrough'), 2);
    const afPercentage = round(meanBy(stores, 'afPercentage'), 3);
    const { noSeasonalBuy } = stores[0];

    const updatedValues = stores.map((store) => {
        const presentationStocks = calculatePresentationStocks(
            store.rateOfSale,
            store.weeksOnSale,
            store.sellThrough,
            store.noSeasonalBuy
        );
        const salesUnits = calculateSalesUnits(store.weeksOnSale, store.rateOfSale);
        const openToBuyUnits = calculateOpenToBuyUnits(
            store.beginningOfPeriodUnits,
            store.sellThrough,
            store.rateOfSale,
            store.weeksOnSale,
            store.noSeasonalBuy,
            channelId
        );
        const afOpenToBuyUnits = calculateAfOpenToBuyUnits({
            typeOfOrder,
            beginningOfPeriodUnits: store.beginningOfPeriodUnits,
            sellThrough: store.sellThrough,
            rateOfSale: store.rateOfSale,
            weeksOnSale: store.weeksOnSale,
            noSeasonalBuy: store.noSeasonalBuy,
            channelId,
            afPercentage: store.afPercentage,
        });

        const aaOpenToBuyUnits = calculateAaOpenToBuyUnits({
            typeOfOrder,
            beginningOfPeriodUnits: store.beginningOfPeriodUnits,
            sellThrough: store.sellThrough,
            rateOfSale: store.rateOfSale,
            weeksOnSale: store.weeksOnSale,
            noSeasonalBuy: store.noSeasonalBuy,
            channelId,
            afPercentage: store.afPercentage,
        });

        const endOfPeriodUnits = calculateEndOfPeriodUnits(
            store.beginningOfPeriodUnits,
            store.sellThrough,
            store.rateOfSale,
            store.weeksOnSale,
            store.noSeasonalBuy,
            channelId,
            typeOfOrder
        );
        return {
            presentationStocks,
            openToBuyUnits,
            aaOpenToBuyUnits,
            afOpenToBuyUnits,
            endOfPeriodUnits,
            minimumBuyTooLow: getMinimumBuyTooLow(store.minimumBuyMaterial, store.allocationUnits, openToBuyUnits),
            salesUnits,
        };
    });

    return {
        rateOfSale,
        weeksOnSale,
        sellThrough,
        afPercentage,
        presentationStocks: sumBy(updatedValues, 'presentationStocks'),
        openToBuyUnits: sumBy(updatedValues, 'openToBuyUnits'),
        aaOpenToBuyUnits: sumBy(updatedValues, 'aaOpenToBuyUnits'),
        afOpenToBuyUnits: sumBy(updatedValues, 'afOpenToBuyUnits'),
        endOfPeriodUnits: sumBy(updatedValues, 'endOfPeriodUnits'),
        sales: sumBy(updatedValues, 'salesUnits'),
        minimumBuyTooLow: sumBy(updatedValues, 'minimumBuyTooLow'),
        noSeasonalBuy,
    };
};

/**
 * Calculates the maximum ST% value a user should enter at aggregatedMaterial-level so that the ROS value
 * on that same aggregatedMaterial can meet all minimum buy criteria when set lower than suggested.
 */
export const calculateMaxSellThrough = (stores: BuyPlanStore[]): number => {
    // Calculates one ST per each single store
    const storesSellThrough = stores.map(({ minimumBuyMaterial, allocationUnits, rateOfSale, weeksOnSale }) => {
        // The normalization applied (0.4999999) is to avoid having more materials than needed
        const maxMinimumBuy = Math.max(minimumBuyMaterial ?? 0, allocationUnits ?? 0) - 0.4999999;
        // The store ST we would like to have, rounded down to avoid a calculation that would be too high
        const storeMaxSellThrough = floor(((rateOfSale || 1) * weeksOnSale) / (maxMinimumBuy || 1), 2);
        return storeMaxSellThrough;
    });

    // Finds the minimum of the stores STs we need to meet to reach the entire aggregatedMaterial minimumBuy
    const roundedSellThrough = floor(min(storesSellThrough) || 0, 2);

    // The number will be given back as a floating point one (eg. 0.55), which will need to be transformed as a %, if needed
    return roundedSellThrough;
};

/* Checks to see if the user can make manual updates to open to buy units
Manual updates should not be permitted in the following cases:
- noSeasonalBuy is set to true (all channels)
- beginningOfPeriodUnits are >= presentation stocks - sales units (digital only)
*/

export const canUpdateOTBUnits = (
    channelId: number,
    beginningOfPeriodUnits: number,
    sellThrough: number,
    rateOfSale: number,
    weeksOnSale: number,
    noSeasonalBuy: boolean
) => {
    if (noSeasonalBuy) {
        return false;
    }
    const presentationStocks = Math.round((rateOfSale * weeksOnSale) / sellThrough);
    if (channelId !== channels.digital.id && beginningOfPeriodUnits >= presentationStocks - rateOfSale * weeksOnSale) {
        return false;
    }
    return true;
};

export const calculateOTBAggregatedMaterialUnits = (
    store: Pick<
        BuyPlanStore,
        'beginningOfPeriodUnits' | 'sellThrough' | 'rateOfSale' | 'weeksOnSale' | 'noSeasonalBuy' | 'afPercentage'
    >,
    typeOfOrder: string,
    channelId: number
): {
    aaOpenToBuyUnits: number;
    afOpenToBuyUnits: number;
    openToBuyUnits: number;
} => {
    const { beginningOfPeriodUnits, sellThrough, rateOfSale, weeksOnSale, noSeasonalBuy, afPercentage } = store;
    const aaOpenToBuyUnits = calculateAaOpenToBuyUnits({
        beginningOfPeriodUnits,
        sellThrough,
        rateOfSale,
        weeksOnSale,
        noSeasonalBuy,
        channelId,
        typeOfOrder,
        afPercentage,
    });
    const afOpenToBuyUnits = calculateAfOpenToBuyUnits({
        beginningOfPeriodUnits,
        sellThrough,
        rateOfSale,
        weeksOnSale,
        noSeasonalBuy,
        channelId,
        typeOfOrder,
        afPercentage,
    });
    const openToBuyUnits = calculateOpenToBuyUnits(
        beginningOfPeriodUnits,
        sellThrough,
        rateOfSale,
        weeksOnSale,
        noSeasonalBuy,
        channelId
    );

    return {
        aaOpenToBuyUnits,
        afOpenToBuyUnits,
        openToBuyUnits,
    };
};

export const calculateOTBUnitsBasedOnAaUpdate = (updatedAaOtbUnits: number, store: BuyPlanStore) => {
    const { beginningOfPeriodUnits, rateOfSale, weeksOnSale } = store;
    const afOpenToBuyUnits = store.afOpenToBuyUnits ?? 0;
    const updatedOpenToBuyUnitsTotal = afOpenToBuyUnits + updatedAaOtbUnits;

    const updatedAfPercentage = round(afOpenToBuyUnits / updatedOpenToBuyUnitsTotal, 3);

    const updatedSellThrough = calculateUpdatedSellThrough(
        beginningOfPeriodUnits,
        updatedOpenToBuyUnitsTotal,
        rateOfSale,
        weeksOnSale
    );

    return {
        afOpenToBuyUnits,
        aaOpenToBuyUnits: updatedAaOtbUnits,
        openToBuyUnits: updatedOpenToBuyUnitsTotal,
        afPercentage: updatedAfPercentage,
        sellThrough: updatedSellThrough,
    };
};

export const calculateOTBUnitsBasedOnAfUpdate = (updatedAfOtbUnits: number, store: BuyPlanStore) => {
    const { beginningOfPeriodUnits, rateOfSale, weeksOnSale } = store;
    const aaOpenToBuyUnits = store.aaOpenToBuyUnits ?? 0;
    const updatedOpenToBuyUnitsTotal = aaOpenToBuyUnits + updatedAfOtbUnits;

    const updatedAfPercentage = round(updatedAfOtbUnits / updatedOpenToBuyUnitsTotal, 3);

    const updatedSellThrough = calculateUpdatedSellThrough(
        beginningOfPeriodUnits,
        updatedOpenToBuyUnitsTotal,
        rateOfSale,
        weeksOnSale
    );

    return {
        aaOpenToBuyUnits,
        afOpenToBuyUnits: updatedAfOtbUnits,
        openToBuyUnits: updatedOpenToBuyUnitsTotal,
        afPercentage: updatedAfPercentage,
        sellThrough: updatedSellThrough,
    };
};

export const calculateOTBUnitsBasedOnOtbTotalsUpdate = (
    updatedOpenToBuyUnits: number,
    store: BuyPlanStore,
    typeOfOrder: string,
    channelId: number
) => {
    const { beginningOfPeriodUnits, rateOfSale, weeksOnSale, afPercentage } = store;

    const updatedSellThrough = calculateUpdatedSellThrough(
        beginningOfPeriodUnits,
        updatedOpenToBuyUnits,
        rateOfSale,
        weeksOnSale
    );

    const updatedAfOpenToBuyUnits = calculateAfOpenToBuyUnits({
        typeOfOrder,
        channelId,
        beginningOfPeriodUnits: store.beginningOfPeriodUnits,
        sellThrough: updatedSellThrough,
        rateOfSale: store.rateOfSale,
        weeksOnSale: store.weeksOnSale,
        noSeasonalBuy: store.noSeasonalBuy,
        afPercentage: store.afPercentage,
    });
    const updatedAaOpenToBuyUnits = calculateAaOpenToBuyUnits({
        typeOfOrder,
        channelId,
        beginningOfPeriodUnits: store.beginningOfPeriodUnits,
        sellThrough: updatedSellThrough,
        rateOfSale: store.rateOfSale,
        weeksOnSale: store.weeksOnSale,
        noSeasonalBuy: store.noSeasonalBuy,
        afPercentage: store.afPercentage,
    });

    return {
        afPercentage,
        aaOpenToBuyUnits: updatedAaOpenToBuyUnits,
        afOpenToBuyUnits: updatedAfOpenToBuyUnits,
        openToBuyUnits: updatedOpenToBuyUnits,
        sellThrough: updatedSellThrough,
    };
};

export const calculateAfPercentage = (store: Pick<BuyPlanStore, 'openToBuyUnits' | 'afOpenToBuyUnits'>) =>
    !store.openToBuyUnits || !store.afOpenToBuyUnits ? 0 : round(store.afOpenToBuyUnits / store.openToBuyUnits, 3);

export const normalizeAggregatedMaterialDataBasedOnOTBUnits = (
    store: BuyPlanStore,
    typeOfOrder: string,
    channelId: number
): BuyPlanStore => {
    const calculatedOpenToBuyUnits = calculateOpenToBuyUnits(
        store.beginningOfPeriodUnits,
        store.sellThrough,
        store.rateOfSale,
        store.weeksOnSale,
        store.noSeasonalBuy,
        channelId
    );

    if (store.openToBuyUnits !== calculatedOpenToBuyUnits) {
        const updatedSellThrough = calculateUpdatedSellThrough(
            store.beginningOfPeriodUnits,
            calculatedOpenToBuyUnits,
            store.rateOfSale,
            store.weeksOnSale
        );

        const otbUnits = calculateOTBAggregatedMaterialUnits(
            {
                ...store,
                sellThrough: updatedSellThrough,
            },
            typeOfOrder,
            channelId
        );

        return {
            ...store,
            ...otbUnits,
            sellThrough: updatedSellThrough,
            afPercentage: calculateAfPercentage(otbUnits),
        };
    }
    return {
        ...store,
        afPercentage: calculateAfPercentage(store),
    };
};
