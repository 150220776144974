import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
    FilteringMetaData,
    PagingMetadata,
    MainFiltersExtended,
    AssortmentData,
    AssortmentRecord,
    LoadingStatus,
    NewRelicEventName,
    NewRelicEventStatus,
    AssortmentClusterAction,
} from 'buyplan-common';
import { ActiveFiltersType, InProgressEvents, UserState } from '../../reducers/user';
import { getMissingMainFilterKeys } from '../../helpers/utils';
import MainFilters from '../Filters/MainFilters/MainFilters';
import Loader from '../Loader/Loader';
import {
    addToAssortment,
    removeAssortment,
    getAssortmentMetaData as getAssortmentMetaDataAction,
    setAssortmentData as setAssortmentDataAction,
    updateAssortmentCluster as updateAssortmentClusterAction,
} from '../../actions/assortment';
import './ViewAssortment.scss';
import { getAssortment } from '../../services/assortmentService';
import { PageView } from '../../constants/appConfig';
import { sendCustomNewRelicEvent } from '../../actions/user';
import ViewAssortmentList from './ViewAssortmentList';

const ITEMS_PER_PAGE = 50;

// Manage singleton abort controllers outside component to not to cause a re-render
let abortAssortmentController: AbortController;
let abortMetaDataController: AbortController;

const resetAbortController = (type: 'assortment' | 'metaData') => {
    if (type === 'assortment') {
        if (abortAssortmentController) {
            abortAssortmentController.abort();
        }
        abortAssortmentController = new AbortController();
    } else {
        if (abortMetaDataController) {
            abortMetaDataController.abort();
        }
        abortMetaDataController = new AbortController();
    }
};

type Pagination = PagingMetadata & { totalFetched: number }; // totalFetched is a syntetic pagination field created in this component

interface Props {
    activeFilters: ActiveFiltersType;
    metaData: FilteringMetaData;
    isLoading: boolean;
    getAssortmentMetaData(
        channelId: number,
        includeSubFilters?: boolean,
        filterTypes?: (keyof MainFiltersExtended)[],
        abortSignal?: AbortSignal
    ): void;
    setAssortmentData(data: AssortmentData, page: number): void;
    channelId: number;
    removeAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    addToAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    updateAssortmentCluster({
        channelId,
        action,
        materialCode,
        clusterId,
        clusterAssortmentStores,
    }: {
        channelId: number;
        action: AssortmentClusterAction;
        materialCode: string;
        clusterId: string;
        clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
    }): Promise<void>;
    storeNumberOrder?: string[];
    sendCustomNewRelicEvent(event: NewRelicEventName, status: NewRelicEventStatus): void;
    events: InProgressEvents;
}

interface State {
    loadingAssortment: boolean;
    pagination?: Pagination;
    orderBy: { columnKey: string; direction: 'ASC' | 'DESC' };
}

export class ViewAssortment extends PureComponent<Props, State> {
    state: State = {
        loadingAssortment: false,
        orderBy: { columnKey: 'materialCode', direction: 'ASC' },
    };

    componentDidMount() {
        // Here we send an event to track the amount of time it takes to load data on the page
        this.props.sendCustomNewRelicEvent(NewRelicEventName.assortmentInitialLoad, NewRelicEventStatus.start);
        this.getAssortment();
        this.getAssortmentMetaData();
    }

    componentDidUpdate(prevProps: Props) {
        const { activeFilters } = this.props;
        const hasActiveChannelChanged = prevProps.channelId !== this.props.channelId;
        if (hasActiveChannelChanged) {
            this.getAssortment();
            this.getAssortmentMetaData();
        } else if (
            // Do not update unless there is an update to filters or store order and all required filters are present
            (!isEqual(prevProps.activeFilters, this.props.activeFilters) ||
                !isEqual(prevProps.storeNumberOrder, this.props.storeNumberOrder)) &&
            !!activeFilters.mainFilters.partner &&
            !!activeFilters.mainFilters.category &&
            !!activeFilters.mainFilters.division
        ) {
            // Here we send an event to track the amount of time it takes to load assortment after applying main or subfilters
            this.props.sendCustomNewRelicEvent(NewRelicEventName.applyAssortmentFilters, NewRelicEventStatus.start);
            this.getAssortment();
            this.getAssortmentMetaData();
        }
    }

    componentWillUnmount() {
        if (abortAssortmentController) abortAssortmentController.abort();
        if (abortMetaDataController) abortMetaDataController.abort();
    }

    getAssortment = async (page = 0, orderColumn?: string, orderDirection?: 'ASC' | 'DESC') => {
        const { activeFilters, setAssortmentData } = this.props;
        const { mainFilters, subFilters } = activeFilters;
        const { orderBy } = this.state;

        if (getMissingMainFilterKeys(mainFilters).length > 0) {
            return;
        }

        resetAbortController('assortment');
        this.setState((state) => ({
            loadingAssortment: true,
            pagination: { ...state.pagination, page } as Pagination,
        }));

        const trackingEvent =
            this.props.events && this.props.events[NewRelicEventName.applyAssortmentFilters]
                ? NewRelicEventName.applyAssortmentFilters
                : NewRelicEventName.assortmentInitialLoad;

        try {
            const { data, meta: pagination } = await getAssortment(
                { ...mainFilters, ...subFilters },
                page,
                ITEMS_PER_PAGE,
                // If we don't have an orderColumn we use the one currently used in the state
                orderColumn || orderBy.columnKey,
                orderDirection || orderBy.direction,
                abortAssortmentController.signal
            );

            setAssortmentData(data, page);
            this.setState({
                loadingAssortment: false,
                pagination: { ...(pagination as PagingMetadata), totalFetched: data.materials.length },
            });
            // Send an event to mark the end of data loading (either initial load or after filters are applied)
            this.props.sendCustomNewRelicEvent(trackingEvent, NewRelicEventStatus.end);
            // If the ordering has been specified and the call has been a success, we can save the ordering options
            if (orderColumn && orderDirection) {
                this.setState({ orderBy: { columnKey: orderColumn, direction: orderDirection } });
            }
        } catch (error: unknown) {
            const err = error as Error;
            if (err.name === 'AbortError') {
                return; // another request started
            }
            this.setState({ loadingAssortment: false });
            // Send an event to mark loading end due to error
            this.props.sendCustomNewRelicEvent(trackingEvent, NewRelicEventStatus.error);
        }
    };

    getAssortmentMetaData = () => {
        resetAbortController('metaData');
        this.props.getAssortmentMetaData(
            this.props.channelId,
            true,
            ['partner', 'category', 'division'],
            abortMetaDataController.signal
        );
    };

    updateMetaData = () => {
        this.getAssortmentMetaData();
    };

    render() {
        const { metaData, activeFilters, channelId, isLoading } = this.props;
        const { mainFilters: activeMainFilters } = activeFilters;
        const { loadingAssortment, pagination, orderBy } = this.state;

        if (!metaData) {
            return <Loader />;
        }

        const { mainFilters } = metaData;

        return (
            <div className="ViewAssortment">
                <MainFilters
                    filters={mainFilters}
                    activeChannelId={channelId}
                    activeMainFilters={activeMainFilters}
                    view={PageView.assortment}
                />
                <ViewAssortmentList
                    loading={loadingAssortment || isLoading}
                    pagination={pagination}
                    getAssortment={this.getAssortment}
                    orderBy={orderBy}
                    removeAssortment={this.props.removeAssortment}
                    addToAssortment={this.props.addToAssortment}
                    updateMetaData={this.updateMetaData}
                    updateAssortmentCluster={this.props.updateAssortmentCluster}
                />
            </div>
        );
    }
}

const mapActionCreators = {
    getAssortmentMetaData: getAssortmentMetaDataAction,
    setAssortmentData: setAssortmentDataAction,
    updateAssortmentCluster: updateAssortmentClusterAction,
    removeAssortment,
    addToAssortment,
    sendCustomNewRelicEvent,
};

const mapStateToProps = ({
    assortment: { metaData, loadingStatus },
    user: {
        settings: { activeFilters, activeChannelId, channels },
        events,
    },
}: {
    assortment: { metaData: FilteringMetaData; loadingStatus: LoadingStatus };
    user: UserState;
}) => ({
    metaData,
    isLoading: !loadingStatus || loadingStatus === LoadingStatus.loading,
    activeFilters: {
        mainFilters: activeFilters[`${activeChannelId}|${PageView.assortment}`]?.mainFilters ?? {},
        subFilters: activeFilters[`${activeChannelId}|${PageView.assortment}`]?.subFilters ?? {},
    },
    events,
    channelId: activeChannelId,
    storeNumberOrder: channels.find(({ channelId }) => channelId === activeChannelId)?.storeNumberOrder,
});

export default connect(mapStateToProps, mapActionCreators)(ViewAssortment);
