import { request } from '../api/api';

export const getFeatureFlags = async (): Promise<{ data: { [key: string]: boolean } }> => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/feature-flags/`,
    };
    // eslint-disable-next-line no-return-await
    return await request<{ data: { [key: string]: boolean } }>(requestOptions, true);
};
