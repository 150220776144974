import { SET_FEATURE_FLAGS } from '../actions/actionTypes';
import type { FeatureFlagsActions } from '../actions/featureFlags';

export type FeatureFlag = {
    enabled: boolean;
    value: string;
};

export type FeatureFlagsState = {
    [key: string]: FeatureFlag;
};

const initialState = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: FeatureFlagsActions) => {
    if (action.type === SET_FEATURE_FLAGS) {
        return action.featureFlags || initialState;
    }

    return state;
};
