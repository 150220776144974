import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import GlobalError from '../components/GlobalError/GlobalError';
import Header from '../components/Header/Header';
import Authorizer from '../components/Authorizer/Authorizer';
import Notifier from '../components/Notifications/Notifier';
import WebSocketConnector from '../components/Notifications/WebSocketConnector';
import FeatureFlagsProvider from '../components/FeatureFlags/FeatureFlagsProvider';
import BuyplanRoutes from './BuyplanRoutes';
import './Routes.scss';

function Routes() {
    return (
        <BrowserRouter>
            <div className="routes">
                <GlobalError />
                <Security
                    issuer={process.env.REACT_APP_OKTA_ISSUER}
                    clientId={process.env.REACT_APP_OKTA_CLIENTID}
                    redirectUri={`${window.location.origin}/implicit/callback`}
                    response_type="token"
                    pkce={false}
                >
                    <Authorizer>
                        <FeatureFlagsProvider>
                            <>
                                <Header />
                                <Notifier />
                                <WebSocketConnector />
                                <Switch>
                                    {/* Home and BuyPlan (routes) */}
                                    <Route path="/buyplan" component={BuyplanRoutes} />
                                    <Redirect to="/buyplan" />
                                </Switch>
                            </>
                        </FeatureFlagsProvider>
                    </Authorizer>
                </Security>
            </div>
        </BrowserRouter>
    );
}

export default Routes;
