import { AsyncJobStatusEnum } from 'buyplan-common';
import axios, { AxiosProgressEvent } from 'axios';
import { globalErrorHandler, OnProgress, ResponseError } from '../api/api';
import { UploadFileError } from './uploadFileService';

const defaultTimeout = 5000;

const timeoutPromise = (time: number) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(1);
        }, time);
    });

export type AsyncJobStatus = {
    status: AsyncJobStatusEnum;
    eta?: number;
    createdAt?: string;
    jobId: string;
    response: string;
};
type AsyncJobStatusFunc = () => Promise<AsyncJobStatus>;
type RecursionAsyncJobGetStatusFunc = (fetchStatus: AsyncJobStatusFunc) => Promise<AsyncJobStatus>;
export const recursionGetJobStatus: RecursionAsyncJobGetStatusFunc = async (fetchStatus: AsyncJobStatusFunc) => {
    let res: AsyncJobStatus;
    try {
        res = await fetchStatus();
        if (!res) {
            return res;
        }
        if ([AsyncJobStatusEnum.started, AsyncJobStatusEnum.inProgress].includes(res.status)) {
            const timeout = res.eta ? res.eta * 60 : defaultTimeout;
            await timeoutPromise(timeout);
            res = await recursionGetJobStatus(fetchStatus);
        }
        return res;
    } catch (err: unknown) {
        const er = err as ResponseError;
        if (er?.response?.status === 502 || er?.message === 'Failed to fetch') {
            await timeoutPromise(defaultTimeout);
            res = await recursionGetJobStatus(fetchStatus);
            return res;
        }
        throw err;
    }
};

export interface UploadFileWithAsyncJobInput {
    file: File;
    url: string;
    onProgress?: OnProgress;
    fetchStatus?: () => Promise<AsyncJobStatus>;
    mimeType: string;
}

export const uploadFileWithAsyncJob = async (input: UploadFileWithAsyncJobInput): Promise<AsyncJobStatus | undefined> => {
    const { file, url, onProgress, fetchStatus, mimeType } = input;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const changeProgress = onProgress ? (progress: number) => onProgress(progress) : (_: number) => ({});

    let progress = 10; // 10% - for signed url, 50% - for actual file upload, 80% - for data processing
    changeProgress(progress);

    try {
        await axios.put(url, file, {
            headers: {
                'Content-Type': mimeType,
            },
            withCredentials: false,
            onUploadProgress: (p: AxiosProgressEvent) => {
                progress += (50 * p.loaded) / (p.total || 1);
                changeProgress(progress);
            },
        });
    } catch (err) {
        changeProgress(1);
        globalErrorHandler(err as ResponseError);
    }

    let res: AsyncJobStatus | undefined;
    if (fetchStatus) {
        res = await recursionGetJobStatus(fetchStatus);
        if (res?.status === AsyncJobStatusEnum.failed) {
            const message = JSON.parse((res.response || '') as string);

            changeProgress(1);
            throw new UploadFileError(message);
        }
    }
    changeProgress(100);

    return res;
};
