import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useFeatureFlag } from '../../selectors/useFeatureFlags';

export interface FeatureFlagsProps {
    children: ReactElement;
    featureKey: string;
}

function FeatureFlagsGuard({ children, featureKey }: FeatureFlagsProps): ReactElement {
    const featureFlag = useFeatureFlag(featureKey);
    if (!featureFlag?.enabled) return <Redirect to="/buyplan" />;
    return children;
}

export default FeatureFlagsGuard;
