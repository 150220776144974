import { SET_FEATURE_FLAGS } from './actionTypes';

export const setFeatureFlagsAction = (featureFlags: { [key: string]: boolean }) => ({
    type: SET_FEATURE_FLAGS,
    featureFlags,
});

type SetFeatureFlagsAction = ReturnType<typeof setFeatureFlagsAction>;

export type FeatureFlagsActions = SetFeatureFlagsAction;
