import React, { useCallback } from 'react';
import { round } from 'lodash';
import cn from 'classnames';
import { faPlusCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    BuyPlanAggregatedMaterial,
    BuyPlanStore,
    BuyplanViewColumnConfig,
    calculateEndOfPeriodUnits,
    calculatePresentationStocks,
    calculateSalesUnits,
    canUpdateDigitalBuyplan,
    ChannelRole,
    channels,
    formatUSDOrDash,
    PartnerMaterialReviewedStatus,
    viewBuyplanColumns,
} from 'buyplan-common';
import { calculateAfPercentage, calculateOTBAggregatedMaterialUnits } from '../../helpers/aggregatedMaterial';
import { findColumn, generateCellStyle, getColumnRangeWidth, getVisibleColumnWidth } from '../../helpers/tableTools';
import LastYearData from '../LastYearData/LastYearData';
import InputPercentage from '../SellThroughInput/SellThroughInput';
import AFPercentageInput from '../AFPercentageInput/AFPercentageInput';
import AggregatedMaterialReviewedStatusDot from '../ViewBuyplan/AggregatedMaterialReviewedStatusDot';
import StoreOpenToBuyUnitsDot from '../ViewBuyplan/StoreOpenToBuyUnitsDot';
import Tooltip, { TooltipType } from '../Tooltip/Tooltip';
import Loader from '../Loader/Loader';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import BuyPlanRateOfSaleInput from './BuyPlanRateOfSaleInput';
import BuyPlanWeeksOnSaleInput from './BuyPlanWeeksOnSaleInput';
import BuyPlanUKSalesMarginImpactInput from './BuyPlanUKSalesMarginImpactInput';
import './BuyplanAggregatedMaterialDetailRow.scss';
import BuyplanOpenToBuyTooltip from './BuyplanOpenToBuyTooltip';

interface Props {
    onStoreChange(store: BuyPlanStore): void;
    onStoreBlur(): void;
    isFavorite: boolean;
    columns: BuyplanViewColumnConfig[];
    aggregatedMaterial: BuyPlanAggregatedMaterial;
    store: BuyPlanStore;
    onRemoveStore(store: { storeNumber: string; storeName: string; storeId: string }): void;
    loadingRemovedStore?: string | boolean;
    channelId: number;
    showMessage: (message: string) => void;
    onChangeStoreOTB(store: BuyPlanStore): void;
    rowNumber?: number;
    setBuyPlanPartnerReviewedStatus(
        materialId: string,
        partnerIds: string[],
        reviewedStatus: PartnerMaterialReviewedStatus
    ): void;
}

function BuyplanAggregatedMaterialDetailRow({
    onStoreChange,
    isFavorite,
    columns,
    store,
    aggregatedMaterial,
    onStoreBlur,
    onRemoveStore,
    loadingRemovedStore,
    channelId,
    showMessage,
    rowNumber,
    setBuyPlanPartnerReviewedStatus,
}: Props) {
    const { typeOfOrder } = aggregatedMaterial;
    const {
        storeId,
        storeName,
        storeNumber,
        rateOfSale,
        sellThrough,
        afPercentage,
        weeksOnSale,
        localWholesaleUSD,
        aur,
        beginningOfPeriodUnits,
        noSeasonalBuy,
        aaOpenToBuyUnits,
        afOpenToBuyUnits,
        openToBuyUnits,
        partnerId,
        partnerName,
        partnerReviewStatus,
    } = store;

    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    const cellStyle = useCallback(
        (columnKey: string) => generateCellStyle('BuyplanAggregatedMaterialDetailRow', columnKey, columns),
        [columns]
    );

    // The dynamic space before and after the Store (name and number) are dependent on materialCode and
    // beginningOfPeriodUnits being not "hidable" by the user
    // 1. Store name and number take all the available space of visible fields from Category till Stores field (included, always an empty field).
    const storeNameWidth =
        getVisibleColumnWidth(
            viewBuyplanColumns,
            columns,
            viewBuyplanColumns.filter(({ type }) => type === 'scrollable')[0].key,
            'stores'
        ) - 5;

    // Check if all stores has BOP as zero.
    const areAllBOPNotSet = aggregatedMaterial.stores.every(
        (aggregatedMaterialStore: BuyPlanStore) => aggregatedMaterialStore.beginningOfPeriodUnits === 0
    );

    // Whenever a store is removed, all the other delete buttons get disabled
    const isRemoveStoreButtonDisabled = (!!loadingRemovedStore && loadingRemovedStore !== storeNumber) || isReadOnlyUser;

    // On AF% Change we need to re-calculate AA and AF OTB Units accordingly
    const onAfPercentageChange = (updatedAfPercentage: number) => {
        const { aaOpenToBuyUnits: updatedAaOpenToBuyUnits, afOpenToBuyUnits: updatedAfOpenToBuyUnits } =
            calculateOTBAggregatedMaterialUnits(
                {
                    beginningOfPeriodUnits,
                    sellThrough,
                    rateOfSale,
                    weeksOnSale,
                    noSeasonalBuy,

                    afPercentage: updatedAfPercentage,
                },
                typeOfOrder,
                channelId
            );

        onStoreChange({
            ...store,
            afPercentage: updatedAfPercentage,
            aaOpenToBuyUnits: updatedAaOpenToBuyUnits,
            afOpenToBuyUnits: updatedAfOpenToBuyUnits,
        });
    };

    const presentationStocks = calculatePresentationStocks(rateOfSale, weeksOnSale, sellThrough, noSeasonalBuy);

    const isROSDisabled = !canUpdateDigitalBuyplan(channelId, storeNumber, typeOfOrder);

    const endOfPeriodUnits = calculateEndOfPeriodUnits(
        beginningOfPeriodUnits,
        sellThrough,
        rateOfSale,
        weeksOnSale,
        noSeasonalBuy,
        channelId,
        typeOfOrder
    );

    /*
      When we change AF% we change AA OTB and AF OTB Units according to the new AF% value.
      Due to the fact that OTB Units cannot be float numbers we need to normalize AF% value to reflect the rounded numbers of AA/AF OTB Units.
      */
    const handleAFPercentageBlur = useCallback(() => {
        const normalizedAfPercentage = calculateAfPercentage(store);
        if (store.afPercentage !== normalizedAfPercentage) {
            const normalizedStore = {
                ...store,
                afPercentage: normalizedAfPercentage,
            };

            onStoreChange(normalizedStore);

            showMessage(
                `Could not set AF% to ${round(store.afPercentage * 100, 3)}%, has been set to ${round(
                    normalizedAfPercentage * 100,
                    3
                )}%.
                This is because AF OTB and AA OTB values are calculated based on AF % and must be whole numbers.`
            );
        }

        onStoreBlur();
    }, [store, onStoreChange, onStoreBlur, showMessage]);

    const resetPartnerMaterialReviewedStatus = async () => {
        let reviewedStatus = PartnerMaterialReviewedStatus.notReviewed;

        if (partnerReviewStatus === PartnerMaterialReviewedStatus.notReviewed) {
            reviewedStatus = PartnerMaterialReviewedStatus.manuallyUpdated;
        }

        setBuyPlanPartnerReviewedStatus(aggregatedMaterial.materialId, [partnerId], reviewedStatus);
    };

    return (
        <tr
            className="BuyplanAggregatedMaterialDetailRow"
            key={storeName}
            data-e2e={`aggregatedMaterial-store-${storeNumber}`}
        >
            <td style={{ width: storeNameWidth }}>
                <div className="BuyplanAggregatedMaterialDetailRow__store">
                    {rowNumber === 0 ? (
                        <>
                            <div className="BuyplanAggregatedMaterialDetailRow__reviewedStatus">
                                <AggregatedMaterialReviewedStatusDot
                                    reviewedStatus={partnerReviewStatus}
                                    onClick={resetPartnerMaterialReviewedStatus}
                                />
                            </div>
                            <div className="BuyplanAggregatedMaterialDetailRow__partnerName">{partnerName}</div>
                        </>
                    ) : null}
                    <div className="BuyplanAggregatedMaterialDetailRow__storeName">
                        {isFavorite && (
                            <FontAwesomeIcon
                                icon={faStar as IconProp}
                                className="BuyplanAggregatedMaterialDetailRow__star"
                            />
                        )}
                        {storeName}
                    </div>
                    <div className="BuyplanAggregatedMaterialDetailRow__storeNumber">{storeNumber}</div>
                    <div>
                        {loadingRemovedStore && loadingRemovedStore === storeNumber ? (
                            <Loader width={12} className="BuyplanAggregatedMaterialDetailRow__loading-delete" />
                        ) : (
                            <Tooltip
                                type={TooltipType.error}
                                placement="right"
                                tooltip={isRemoveStoreButtonDisabled ? '' : 'Remove store'} // Do not show a tooltip if disabled
                            >
                                <button
                                    type="button"
                                    onClick={() => onRemoveStore({ storeNumber, storeName, storeId })}
                                    className={cn('BuyplanAggregatedMaterialDetailRow__delete-button', {
                                        'BuyplanAggregatedMaterialDetailRow__delete-button--disabled':
                                            isRemoveStoreButtonDisabled,
                                    })}
                                    disabled={isRemoveStoreButtonDisabled}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle as IconProp} color="black" />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </td>
            <td {...cellStyle('beginningOfPeriodUnits')}>{areAllBOPNotSet ? null : beginningOfPeriodUnits}</td>
            <td {...cellStyle('marginImpact')}>
                <BuyPlanUKSalesMarginImpactInput
                    name="marginImpact"
                    store={store}
                    onChange={onStoreChange}
                    onBlur={onStoreBlur}
                    disabled={isReadOnlyUser}
                />
            </td>
            <td {...cellStyle('ukSalesPercentage')}>
                <BuyPlanUKSalesMarginImpactInput
                    name="ukSalesPercentage"
                    store={store}
                    onChange={onStoreChange}
                    onBlur={onStoreBlur}
                    disabled={isReadOnlyUser}
                />
            </td>
            <td {...cellStyle('rateOfSale')}>
                <BuyPlanRateOfSaleInput
                    store={store}
                    onChange={onStoreChange}
                    onBlur={onStoreBlur}
                    isNBYIPPStore={isROSDisabled}
                    disabled={isReadOnlyUser}
                />
            </td>
            <td {...cellStyle('weeksOnSale')}>
                <BuyPlanWeeksOnSaleInput
                    store={store}
                    onChange={onStoreChange}
                    onBlur={onStoreBlur}
                    disabled={isReadOnlyUser}
                />
            </td>
            {findColumn(columns, 'eop') && <td {...cellStyle('eop')}>{endOfPeriodUnits}</td>}
            <td {...cellStyle('sellThrough')}>
                <InputPercentage
                    value={sellThrough}
                    name="sellThrough"
                    onChange={(value: number) => onStoreChange({ ...store, sellThrough: value })}
                    onBlur={onStoreBlur}
                    disabled={isReadOnlyUser}
                />
            </td>
            <td {...cellStyle('afPercentage')}>
                <AFPercentageInput
                    value={afPercentage}
                    store={store}
                    name="afPercentage"
                    onChange={onAfPercentageChange}
                    onBlur={handleAFPercentageBlur}
                    disabled={isReadOnlyUser}
                />
            </td>
            <td {...cellStyle('presentationStocks')}>{presentationStocks}</td>
            {findColumn(columns, 'openToBuyUnits') && (
                <td {...cellStyle('openToBuyUnits')}>
                    <BuyplanOpenToBuyTooltip
                        openToBuyUnits={openToBuyUnits}
                        store={store}
                        aggregatedMaterial={aggregatedMaterial}
                    />
                    <StoreOpenToBuyUnitsDot
                        materialCode={aggregatedMaterial.materialCode}
                        shouldShow={
                            channelId !== channels.digital.id &&
                            beginningOfPeriodUnits >= presentationStocks - rateOfSale * weeksOnSale
                        }
                    />
                </td>
            )}

            {findColumn(columns, 'aaOpenToBuyUnits') && <td {...cellStyle('aaOpenToBuyUnits')}>{aaOpenToBuyUnits ?? 0}</td>}
            {findColumn(columns, 'afOpenToBuyUnits') && <td {...cellStyle('afOpenToBuyUnits')}>{afOpenToBuyUnits ?? 0}</td>}

            {findColumn(columns, 'salesUnits') && (
                <td {...cellStyle('salesUnits')}>
                    {weeksOnSale === null ? '-' : calculateSalesUnits(weeksOnSale, rateOfSale)}
                </td>
            )}
            {findColumn(columns, 'buyWholesale') && (
                <td {...cellStyle('buyWholesale')}>
                    {formatUSDOrDash(
                        openToBuyUnits === null || localWholesaleUSD === null ? null : openToBuyUnits * localWholesaleUSD
                    )}
                </td>
            )}
            {findColumn(columns, 'salesRetail') && (
                <td {...cellStyle('salesRetail')}>
                    {formatUSDOrDash(rateOfSale === null || aur === null ? null : round(rateOfSale * weeksOnSale) * aur)}
                </td>
            )}
            {findColumn(columns, 'favoriteStore') && (
                <td style={{ width: getColumnRangeWidth(columns, 'favoriteStore', 'favoriteStore') }} />
            )}
            {findColumn(columns, 'lastYear') && (
                <td {...cellStyle('lastYear')}>
                    <LastYearData lastYearData={store} level="store" />
                </td>
            )}
        </tr>
    );
}

export default BuyplanAggregatedMaterialDetailRow;
