import React, { CSSProperties, useEffect, useState } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import {
    AssortmentColumnConfig,
    Material,
    AssortmentClusterOptionCounts,
    AssortmentDetails,
    typeOfOrderOptions,
    launchFlagOptions,
    AssortmentRecord,
    MaterialLockedForPartnerAssortment,
    segmentOptions,
    SegmentType,
    FlowRangeType,
    FlowOptions,
    DigitalFlowOptions,
    storesFlowOptions,
    digitalFlowOptions,
    getStartEndWeekFromFlow,
    channels,
    ChannelRole,
    AssortmentStoreOptionCounts,
    Season,
    getValidFlowRangesForLaunch,
    AssortmentClusterAction,
} from 'buyplan-common';
import MaterialImage from '../MaterialImage/MaterialImage';
import MaterialCode from '../MaterialCode/MaterialCode';
import Modal from '../Modal/Modal';
import './ViewAssortmentRow.scss';
import Dropdown from '../Dropdown/Dropdown';
import PercentageInput from '../PercentageInput/PercentageInput';
import { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import Dot, { DotSize, DotType } from '../Dot/Dot';
import { isInvalidInputValue } from '../../helpers/utils';
import SyncMaterialPrices from '../SyncMaterialPrices/SyncMaterialPrices';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import useActiveSeasonData from '../../selectors/useActiveSeasonData';
import ViewAssortmentCell from './ViewAssortmentCell';
import AssortmentCheckAllCheckbox from './AssortmentCheckAllCheckbox';
import AssortmentLock from './AssortmentLock';
import AssortmentCluster from './AssortmentCluster';

interface Props {
    material: Material;
    clusterOptionCounts: AssortmentClusterOptionCounts[];
    assortmentRecords: AssortmentDetails[];
    lockedForPartnersAssortment: MaterialLockedForPartnerAssortment[];
    addToAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    removeAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    updateAssortmentMaterial(materialId: string, material: Material, materialAttributes: Partial<Material>): Promise<void>;
    updateMetaData(): void;
    updateAssortmentCluster({
        channelId,
        action,
        materialCode,
        clusterId,
        clusterAssortmentStores,
    }: {
        channelId: number;
        action: AssortmentClusterAction;
        materialCode: string;
        clusterId: string;
        clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
    }): Promise<void>;
    columnType: 'fixed' | 'scrollable';
    style: CSSProperties;
    columns: AssortmentColumnConfig[];
    channelId: number;
    clustersOpenState: { [key: string]: boolean };
}

const getAssortedStoresCount = (materialCode: string, assortmentRecords: AssortmentDetails[]): number =>
    assortmentRecords.reduce((acc, item) => {
        if (materialCode === item.materialCode) {
            return acc + 1;
        }
        return acc;
    }, 0);

const getTotalStoreCount = (clusterOptionCounts: AssortmentClusterOptionCounts[], material: Material): number => {
    const categoryDivisionPair = { category: material.category, division: material.division };
    return clusterOptionCounts
        .reduce((acc, item) => [...acc, ...item.storeOptionCounts], [] as AssortmentStoreOptionCounts[])
        .filter(({ categoryDivisionPairs }) =>
            categoryDivisionPairs.find(
                (pair) => pair.category === categoryDivisionPair.category && pair.division === categoryDivisionPair.division
            )
        ).length;
};

// Launch Flag select option
const getLaunchFlagOptions = (currentLaunchFlag: string) => {
    const isCurrentLaunchFlagValid = Object.values(launchFlagOptions).includes(currentLaunchFlag);

    const options = [...new Set(Object.values(launchFlagOptions))];
    const optionsArr = options.map((option) => ({
        value: option,
        label: option === '' ? 'none' : option,
    }));
    return isCurrentLaunchFlagValid || currentLaunchFlag === null
        ? optionsArr
        : [
              {
                  value: currentLaunchFlag,
                  label: currentLaunchFlag,
                  disabled: true,
              },
              ...optionsArr,
          ];
};

const getFlowsOptions = (
    membershipLaunchDate: string | null,
    launchDate: string | null,
    season: Season | null,
    flowOptions: FlowOptions | DigitalFlowOptions
) => {
    const launch = membershipLaunchDate || launchDate; // membership launch takes priority
    if (launch) {
        const validFlowRanges = getValidFlowRangesForLaunch(launch, season, flowOptions);
        return Object.values(validFlowRanges).map((option) => ({ label: option, value: option }));
    }
    return Object.values(flowOptions).map((option) => ({ label: option, value: option }));
};

function ViewAssortmentRow({
    material,
    clusterOptionCounts,
    assortmentRecords,
    lockedForPartnersAssortment,
    addToAssortment,
    removeAssortment,
    columnType,
    style,
    columns,
    channelId,
    updateMetaData,
    updateAssortmentMaterial,
    clustersOpenState,
    updateAssortmentCluster,
}: Props) {
    const visibleColumns = columns.filter(({ type }) => type === columnType);
    const [currentTypeOfOrderValue, setCurrentTypeOfOrder] = useState('');
    const [currentSegmentValue, setCurrentSegment] = useState(material.segment);
    const [currentLaunchFlagValue, setCurrentLaunchFlag] = useState(material.launchFlag || launchFlagOptions.empty);
    const [currentStoresFlowValue, setCurrentStoresFlow] = useState(material.storesFlowRange);
    const [currentStoresStartEndWeeks, setCurrentStoresStartEndWeeks] = useState({
        startWeek: material.startWeek || '',
        endWeek: material.endWeek || '',
    });
    const [currentDigitalFlowValue, setCurrentDigitalFlow] = useState(material.digitalFlowRange || digitalFlowOptions.none);
    const [currentDigitalStartEndWeeks, setCurrentDigitalStartEndWeeks] = useState({
        digitalStartWeek: material.digitalStartWeek || '',
        digitalEndWeek: material.digitalEndWeek || '',
    });
    const [currentUkVatValue, setUkVat] = useState(material.ukVat);
    const [currentVatValue, setVat] = useState(material.vat);
    const [assortAllLoading, setAssortAllLoading] = useState(false);
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);
    const isAdmin = useIsChannelAdmin(channelId);
    const isSuperUser = useIsSuperUser();
    const activeSeasonData = useActiveSeasonData();
    const materialHasLaunchDate = material.membershipLaunchDate || material.launchDate;
    const [error, setError] = useState('');

    useEffect(() => {
        setVat(material.vat);
    }, [material.vat]);

    useEffect(() => {
        setCurrentTypeOfOrder(material.typeOfOrder);
    }, [material.typeOfOrder]);

    useEffect(() => {
        setUkVat(material.ukVat);
    }, [material.ukVat]);

    useEffect(() => {
        setCurrentSegment(material.segment);
    }, [material.segment]);

    const onAttributeChange = async (attributes: Partial<Material>) => {
        try {
            const changedAttributes = Object.keys(attributes).reduce((acc: Partial<Material>, key: string) => {
                const val = attributes[key as keyof Material];
                if (val !== material[key as keyof Material]) {
                    return { ...acc, [key]: val };
                }
                return acc;
            }, {});

            if (Object.keys(changedAttributes).length) {
                await updateAssortmentMaterial(material.id, material, changedAttributes);
                updateMetaData();
            }
        } catch (err) {
            const attributionChangeError = err as Error;
            setError(attributionChangeError.message);
        }
    };

    useEffect(() => {
        setCurrentLaunchFlag(material.launchFlag || launchFlagOptions.empty);
    }, [material.launchFlag]);

    useEffect(() => {
        setCurrentStoresFlow(material.storesFlowRange);
        setCurrentStoresStartEndWeeks({
            startWeek: material.startWeek || '',
            endWeek: material.endWeek || '',
        });
    }, [material.storesFlowRange, material.endWeek, material.startWeek]);

    useEffect(() => {
        setCurrentDigitalFlow(material.digitalFlowRange || digitalFlowOptions.none);
        setCurrentDigitalStartEndWeeks({
            digitalStartWeek: material.digitalStartWeek || '',
            digitalEndWeek: material.digitalEndWeek || '',
        });
    }, [material.digitalFlowRange, material.digitalEndWeek, material.digitalStartWeek]);

    /*
        Updating the stores flow will also update the start week and end week for that material.
    */
    const onStoresFlowChange = (value: FlowRangeType) => {
        if (value !== currentStoresFlowValue) {
            const { startWeek, endWeek } = getStartEndWeekFromFlow(value);
            setCurrentStoresFlow(value);
            // If there is a launch date, the start week should be kept as-is because it is calculated based on the launch date itself
            if (materialHasLaunchDate) {
                setCurrentStoresStartEndWeeks({
                    startWeek: currentStoresStartEndWeeks.startWeek,
                    endWeek: endWeek || '',
                });
                onAttributeChange({
                    storesFlowRange: value,
                    endWeek,
                });
            } else {
                setCurrentStoresStartEndWeeks({
                    startWeek: startWeek || '',
                    endWeek: endWeek || '',
                });
                onAttributeChange({
                    storesFlowRange: value,
                    startWeek,
                    endWeek,
                });
            }
        }
    };

    /*
        This will update (or remove) the digitalFlowRange for the material.
        Updating the digital flow will also update the digital start week and digital end week for that material.
    */
    const onDigitalFlowChange = (value: FlowRangeType) => {
        if (value !== currentDigitalFlowValue) {
            const { startWeek, endWeek } = getStartEndWeekFromFlow(value);
            setCurrentDigitalFlow(value);
            // If there is a launch date, the digital start week should be kept as-is because it is calculated based on the launch date itself
            if (materialHasLaunchDate) {
                setCurrentDigitalStartEndWeeks({
                    digitalStartWeek: currentDigitalStartEndWeeks.digitalStartWeek,
                    digitalEndWeek: endWeek || '',
                });
                onAttributeChange({
                    digitalFlowRange: value === digitalFlowOptions.none ? null : value,
                    digitalEndWeek: endWeek,
                });
            } else {
                setCurrentDigitalStartEndWeeks({
                    digitalStartWeek: startWeek || '',
                    digitalEndWeek: endWeek || '',
                });
                onAttributeChange({
                    digitalFlowRange: value === digitalFlowOptions.none ? null : value,
                    digitalStartWeek: startWeek,
                    digitalEndWeek: endWeek,
                });
            }
        }
    };

    const launchFlagDropdownOptions = getLaunchFlagOptions(currentLaunchFlagValue);
    const storesFlowDropdownOptions = getFlowsOptions(
        material.membershipLaunchDate,
        material.launchDate,
        activeSeasonData,
        storesFlowOptions
    );
    const digitalFlowDropdownOptions = getFlowsOptions(
        material.membershipLaunchDate,
        material.launchDate,
        activeSeasonData,
        digitalFlowOptions
    );
    const typeOfOrderDropdownOptions = Object.values(typeOfOrderOptions).map((option) => ({ label: option, value: option }));
    const segmentDropdownOptions = Object.values(segmentOptions).map((option) => ({ label: option, value: option }));
    const storesCount = getAssortedStoresCount(material.materialCode, assortmentRecords);
    const totalStoresCount = getTotalStoreCount(clusterOptionCounts, material);
    const assortedStoresCount = `${storesCount}/${totalStoresCount}`;

    return (
        <>
            <div
                style={style}
                className="ViewAssortmentRow"
                key={`aggregatedMaterial-${material.materialCode}`}
                data-e2e={`aggregatedMaterial-${material.materialCode}`}
            >
                <div className="ViewAssortmentRow__material">
                    {visibleColumns.map(({ key, label, editable }) => {
                        if (key === 'selectAll') {
                            return (
                                <AssortmentCheckAllCheckbox
                                    setAssortAllLoading={setAssortAllLoading}
                                    assortAllLoading={assortAllLoading}
                                    clusterOptionCounts={clusterOptionCounts}
                                    assortmentRecords={assortmentRecords.filter(
                                        ({ materialCode }) => material.materialCode === materialCode
                                    )}
                                    material={material}
                                    key="selectAll"
                                />
                            );
                        }
                        if (key === 'lock') {
                            return (
                                <AssortmentLock
                                    clusterOptionCounts={clusterOptionCounts}
                                    lockedForPartnersAssortment={lockedForPartnersAssortment}
                                    material={material}
                                    key="lock"
                                />
                            );
                        }

                        return (
                            <ViewAssortmentCell
                                key={`${key}-${kebabCase(label)}`}
                                columnKey={key}
                                material={material}
                                columns={columns}
                                disabled={!editable?.individual || !isAdmin}
                                channelId={channelId}
                                onChange={onAttributeChange}
                            >
                                {key === 'assortedStoresCount' && assortedStoresCount}
                                {key === 'toggleStores' && <MaterialImage material={material} />}
                                {key === 'materialCode' && (
                                    <div className="ViewAssortmentRow__materialCode--container">
                                        {isSuperUser && (
                                            <SyncMaterialPrices
                                                materialCode={material.materialCode}
                                                onChange={onAttributeChange}
                                            />
                                        )}
                                        <MaterialCode
                                            {...material}
                                            onChange={onAttributeChange}
                                            disabled={!editable?.individual || !isAdmin}
                                        />
                                    </div>
                                )}
                                {key === 'typeOfOrder' && (
                                    <Dropdown
                                        options={typeOfOrderDropdownOptions}
                                        onChange={(value: string) => onAttributeChange({ typeOfOrder: value })}
                                        value={currentTypeOfOrderValue || material.typeOfOrder}
                                        disabled={isReadOnlyUser}
                                    />
                                )}
                                {key === 'consumer' && (
                                    <span
                                        className={cn({
                                            'ViewAssortmentRow__cell--error': isInvalidInputValue(material.consumer),
                                        })}
                                    >
                                        {material.consumer}
                                    </span>
                                )}
                                {key === 'segment' && (
                                    <Dropdown
                                        options={segmentDropdownOptions}
                                        onChange={(value: SegmentType) => onAttributeChange({ segment: value })}
                                        value={currentSegmentValue}
                                        disabled={isReadOnlyUser}
                                    />
                                )}
                                {key === 'launchFlag' && (
                                    <Dropdown
                                        options={launchFlagDropdownOptions}
                                        onChange={(value: string) => onAttributeChange({ launchFlag: value })}
                                        value={currentLaunchFlagValue}
                                        disabled={isReadOnlyUser}
                                    />
                                )}
                                {key === 'storesFlowRange' &&
                                    (channelId !== channels.digital.id ? (
                                        <div className="ViewAssortmentRow__storesFlowRangeContainer">
                                            <Dropdown
                                                options={storesFlowDropdownOptions}
                                                onChange={onStoresFlowChange}
                                                value={currentStoresFlowValue}
                                                disabled={isReadOnlyUser}
                                            />
                                            {materialHasLaunchDate && (
                                                <Dot
                                                    type={DotType.info}
                                                    tooltip="The Stores Flow can only be updated within the scope of the launch date."
                                                    placement="top"
                                                    size={DotSize.large}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <span>{currentStoresFlowValue}</span>
                                    ))}
                                {key === 'digitalFlowRange' &&
                                    (channelId === channels.digital.id ? (
                                        <div className="ViewAssortmentRow__digitalFlowRangeContainer">
                                            <Dropdown
                                                options={digitalFlowDropdownOptions}
                                                onChange={onDigitalFlowChange}
                                                value={currentDigitalFlowValue}
                                                disabled={isReadOnlyUser}
                                            />
                                            {materialHasLaunchDate && (
                                                <Dot
                                                    type={DotType.info}
                                                    tooltip="The Digital Flow can only be updated within the scope of the launch date."
                                                    placement="top"
                                                    size={DotSize.large}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <span>{currentDigitalFlowValue}</span>
                                    ))}
                                {/* start week and end week for stores and digital are updated dynamically when storesFlowRange or digitalFlowRange are changed */}
                                {key === 'startWeek' && <span>{currentStoresStartEndWeeks.startWeek}</span>}
                                {key === 'endWeek' && <span>{currentStoresStartEndWeeks.endWeek}</span>}
                                {key === 'digitalStartWeek' && <span>{currentDigitalStartEndWeeks.digitalStartWeek}</span>}
                                {key === 'digitalEndWeek' && <span>{currentDigitalStartEndWeeks.digitalEndWeek}</span>}
                                {key === 'ukVat' && (
                                    <PercentageInput
                                        clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                                        minimum={0}
                                        maximum={100}
                                        value={currentUkVatValue}
                                        onChange={(value: number) => setUkVat(value)}
                                        onBlur={() => onAttributeChange({ ukVat: currentUkVatValue })}
                                        disabled={isReadOnlyUser}
                                    />
                                )}
                                {key === 'vat' && (
                                    <PercentageInput
                                        clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                                        minimum={0}
                                        maximum={100}
                                        value={currentVatValue}
                                        onChange={(value: number) => setVat(value)}
                                        onBlur={() => onAttributeChange({ vat: currentVatValue })}
                                        disabled={isReadOnlyUser}
                                    />
                                )}
                            </ViewAssortmentCell>
                        );
                    })}
                    {columnType === 'scrollable' &&
                        clusterOptionCounts.map((clusterOptionCount) => (
                            <AssortmentCluster
                                key={`${material.materialCode}-${clusterOptionCount.clusterId}`}
                                material={material}
                                clusterOptionCount={clusterOptionCount}
                                assortmentRecords={assortmentRecords}
                                isOpen={clustersOpenState[clusterOptionCount.clusterId]}
                                lockedForPartnersAssortment={lockedForPartnersAssortment}
                                addToAssortment={addToAssortment}
                                removeAssortment={removeAssortment}
                                assortAllLoading={assortAllLoading}
                                updateAssortmentCluster={updateAssortmentCluster}
                            />
                        ))}
                </div>
            </div>
            {error && (
                <Modal
                    style={{ content: { minWidth: 485 }, overlay: { zIndex: 10 } }}
                    isOpen={!!error}
                    title="Attribution change failed"
                    text={error}
                    closeAction={() => setError('')}
                />
            )}
        </>
    );
}

export default ViewAssortmentRow;
