import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ChannelAudience, channels, getChannelAudience } from 'buyplan-common';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Step, Stepper } from '../Stepper/Stepper';
import StepIndicator, { StateIndicator } from '../StepIndicator/StepIndicator';
import SyncPrices from '../SyncPrices/SyncPrices';
import { useSelector } from '../../store/reducers';
import useCanEditBuyPlan from '../../helpers/useCanEditBuyPlan';
import useSeasonSettings from '../../selectors/useSeasonSettings';
import SyncImages from '../SyncImages/SyncImages';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import useUserSettings from '../../selectors/useUserSettings';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import { useIsReconciliationEnabled } from '../../selectors/useFeatureFlags';

function Buyplan() {
    const { currentSeason, allSeason } = useSelector((state) => state.season);
    const activeSeasonId = useSelector((state) => state.user.settings.activeSeasonId);

    const { activeChannelId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const isSuperUser = useIsSuperUser();
    const { isEditableSeason, isHistoricalSeason } = useSeasonSettings();

    const [loading, , canEditBuyPlan, hasOptionCountFile] = useCanEditBuyPlan();
    const isReconciliationEnabled = useIsReconciliationEnabled();

    if (!currentSeason || loading) return null;

    const activeSeason = allSeason?.find(({ id }) => id === activeSeasonId);
    const channelAudience = getChannelAudience(activeChannelId);
    const seasonStartedForAudience = () => {
        if (channelAudience === ChannelAudience.ndsDigital) {
            return 'NDS / DIGITAL SEASON STARTED';
        }
        if (channelAudience === ChannelAudience.nvs) {
            return 'NVS SEASON STARTED';
        }
        return 'SEASON STARTED';
    };

    return (
        <Container>
            <Row>
                <Col xs={{ span: 6, offset: 3 }}>
                    <h1>BUY PLAN</h1>
                    <Stepper>
                        <Step
                            title={`${activeSeason ? activeSeason.name : currentSeason.name} ${seasonStartedForAudience()}`}
                            indicator={<StepIndicator state={StateIndicator.INDICATOR_COMPLETED} />}
                        >
                            {isSuperUser && !isHistoricalSeason && (
                                <Link to="/buyplan/edit-season" className="edit-season Button Button__inverted">
                                    Edit season
                                </Link>
                            )}
                        </Step>
                        {isChannelAdmin && (
                            <Step
                                title="Import"
                                indicator={<StepIndicator state={StateIndicator.INDICATOR_WAITING_ON_USER} />}
                            >
                                {!isHistoricalSeason && (
                                    <>
                                        <Link
                                            to="/buyplan/manage-assortment/import"
                                            className="import-assortment-files Button"
                                        >
                                            Import assortment files
                                        </Link>
                                        <br />
                                    </>
                                )}
                                {isEditableSeason && (
                                    <>
                                        <Link to="/buyplan/import-files" className="import-files Button">
                                            Import buy plan files
                                        </Link>
                                        <br />
                                    </>
                                )}
                                <Link to="/buyplan/manage-assortment/late-adds" className="Button">
                                    Late adds
                                </Link>
                            </Step>
                        )}
                        {isSuperUser && (
                            <Step
                                title="Actions"
                                indicator={<StepIndicator state={StateIndicator.INDICATOR_WAITING_ON_USER} />}
                            >
                                <SyncPrices />
                                <SyncImages />
                            </Step>
                        )}
                        <Step title="Manage" indicator={<StepIndicator state={StateIndicator.INDICATOR_WAITING_ON_USER} />}>
                            <Link to="/buyplan/manage-assortment" className="manage-assortment Button">
                                Assortment
                            </Link>
                            <br />
                            {hasOptionCountFile || activeChannelId === channels.digital.id ? (
                                <Link to="/buyplan/sales-targets" className="sales-targets-link Button">
                                    Sales Target
                                </Link>
                            ) : (
                                <button type="button" className="sales-targets Button" disabled>
                                    Sales Target
                                </button>
                            )}
                            <br />

                            {canEditBuyPlan ? (
                                <Link to="/buyplan/view-buy-plan" className="view-buy-plan Button">
                                    Buy Plan
                                </Link>
                            ) : (
                                <>
                                    <button type="button" className="view-buy-plan Button" disabled>
                                        Buy Plan
                                    </button>
                                    <br />
                                    The buy plan is not available until all sales targets have been set on the store level.
                                </>
                            )}
                        </Step>
                        <Step
                            title="Download"
                            indicator={
                                <StepIndicator
                                    state={StateIndicator.INDICATOR_CUSTOM}
                                    customIcon={faFileDownload as IconProp}
                                />
                            }
                        >
                            <Link to="/buyplan/export-assortment" className="Button">
                                Assortment
                            </Link>
                            <br />
                            <Link to="/buyplan/export-buy-plan" className="Button">
                                Buy Plan
                            </Link>
                        </Step>
                        {isReconciliationEnabled && isChannelAdmin && activeChannelId === channels.nds.id && (
                            <Step
                                title="Reconciliation"
                                indicator={<StepIndicator state={StateIndicator.INDICATOR_WAITING_ON_USER} />}
                            >
                                <Link to="/buyplan/reconciliation" className="reconciliation Button">
                                    ONE NSP
                                </Link>
                            </Step>
                        )}
                        {isSuperUser && (
                            <Step
                                title="Season ended"
                                indicator={
                                    <StepIndicator
                                        state={
                                            isHistoricalSeason
                                                ? StateIndicator.INDICATOR_COMPLETED
                                                : StateIndicator.INDICATOR_IDLE
                                        }
                                    />
                                }
                            >
                                <Link to="/buyplan/new-season" className="new-season Button Button__inverted">
                                    Start new season
                                </Link>
                            </Step>
                        )}
                    </Stepper>
                </Col>
            </Row>
        </Container>
    );
}

export default Buyplan;
